import useWeb3 from './useWeb3';
import { useProfileApi, useApi } from './useAppState';
import { getErc20Contract, getNFTContract, getClientHandlerContract ,getClientHandAddrContract} from '@/utils/ethereum';
import { useMemo, useCallback } from 'react';
import { useWallet } from 'memouse-wallet';
// import Web3 from 'web3';
/* const getcontractAddress =(fweb3,receipt) =>{
	console.log('getcontractAddress');
	const log = receipt.logs[0];
	const topics = log.topics;
	console.log('fweb3.eth.abi.decodeLog',fweb3.eth.abi.decodeLog);
	console.log('fweb3.utils.toChecksumAddress',fweb3.utils.toChecksumAddress);
	const data = log.data;
	// 解码主题数据
	console.log('log.topics',topics);
	const decodedData = fweb3.eth.abi.decodeLog(
		[ {
		type: 'address',
		name: 'profileAddress'
		}],
		data,
		topics.slice(1)
	);
	console.log('log.topics2');
	console.log('decodedData.profileAddress',decodedData.profileAddress);
	// 将地址转换为校验和地址
	const checksumAddress = fweb3.utils.toChecksumAddress(decodedData.profileAddress);
	console.log('decodedData.profileAddress',decodedData.profileAddress);
	console.log('checksumAddress',checksumAddress);
	return checksumAddress;

}; */
export const useErc20 = address => {
	const web3 = useWeb3();
	return useMemo(() => (address && web3 ? getErc20Contract(address, web3) : null), [address, web3]);
};

export const useClientHandler = address => {
	const web3 = useWeb3();
	return useMemo(() => (address && web3 ? getClientHandlerContract(address, web3) : null), [address, web3]);
};
export const useNFTContract = address => {
	const web3 = useWeb3();
	return useMemo(() => (address && web3 ? getNFTContract(address, web3) : null), [address, web3]);
};

export const useProfile = () => {
	const API = useProfileApi();
	const web3 = useWeb3();
	// 获取配置
	async function getProfile(address, chainId) {
		const data = await API.get('/profile/' + chainId + '/' + address);
		return data;
	}

	// 创建合约部署交易数据
	async function build(account, chainId, setIsload,ErrFun) {
		setIsload(true);
		const resdata = await API({
			method: 'POST',
			url: '/profile/' + chainId + '/admin/make',
			headers: {
				'Content-Type': 'application/json'
			},
			data: { apiType: 'build', data: '' }
		});
		if (resdata.data.data) {
			resdata.data.from = account;
			// console.log('build', resdata.data);
			let gasPrice = '';
			if (chainId == '137') {
				gasPrice = await web3.eth.getGasPrice();
				console.log('gasPrice', gasPrice);
				gasPrice = gasPrice.substring(0, 7);
			}
			resdata.data.from = account;
			resdata.data.gas = gasPrice || '2100000';
			resdata.data.gasLimit = '2100000';
			await web3.eth
				.sendTransaction(resdata.data)
				.on('receipt', receipt => {
					// console.log('receipt2', receipt);

					const log = receipt.logs[0];
					const topic = log.topics[1];
					console.log('topic', topic);
					setIsload(false);
					// const checksumAddress = Web3.utils.toChecksumAddress(topic);
					// console.log('checksumAddress');
					// console.log(checksumAddress);
					// const contractAddress= getcontractAddress(web3,receipt);
					// console.log('receipt  contractAddress', contractAddress);
					// register(account, '0x'+topic.substr(-40),chainId,setIsload);
				})
				.on('error', error => {
					setIsload(false);
					if (ErrFun) {
						ErrFun(error);
					}
					console.log('error', error);
				});
			setIsload(false);
			if (ErrFun) {
				ErrFun('');
			}
			return { error: null };
		} else {
			setIsload(false);
			if (ErrFun) {
				ErrFun('profile error:' + resdata.data);
			}
			return { error: 'profile error:' + resdata.data };
		}
	}
	// 创建合约 注册交易数据
	async function register(account, address, chainId, setIsload,ErrFun) {
		setIsload(true);
		const resdata = await API({
			method: 'POST',
			url: '/profile/' + chainId + '/admin/make',
			headers: {
				'Content-Type': 'application/json'
			},
			data: { apiType: 'register', data: address }
		});
		if (resdata.data.data) {
			resdata.data.from = account;
			let gasPrice = '';
			if (chainId == '137') {
				gasPrice = await web3.eth.getGasPrice();
				console.log('gasPrice', gasPrice);
				gasPrice = gasPrice.substring(0, 7);
			}
			resdata.data.from = account;
			resdata.data.gas = gasPrice || '2100000';
			resdata.data.gasLimit = '2100000';
			await web3.eth
				.sendTransaction(resdata.data)
				.on('receipt', receipt => {
					console.log(receipt);
				})
				.on('error', error => {
					setIsload(false);
					if (ErrFun) {
						ErrFun(error);
					}
					console.log('error', error);
				});
			setIsload(false);
			if (ErrFun) {
				ErrFun('');
			}
			return { error: null };
		} else {
			setIsload(false);
			if (ErrFun) {
				ErrFun('profile error:' + resdata.data);
			}
			return { error: 'profile error:' + resdata.data };
		}
	}

	// 获取配置 更新数据交易数据
	async function update(account, chainId, data, setIsload, UpDate,ErrFun) {
		setIsload(true);
		const resdata = await API({
			method: 'POST',
			url: '/profile/' + chainId + '/admin/make',
			headers: {
				'Content-Type': 'application/json'
			},
			data: { apiType: 'update', data }
		});
		if (resdata.data.data) {
			let gasPrice = '';
			if (chainId == '137') {
				gasPrice = await web3.eth.getGasPrice();
				console.log('gasPrice', gasPrice);
				gasPrice = gasPrice.substring(0, 7);
			}
			resdata.data.from = account;
			resdata.data.gas = gasPrice || '2100000';
			resdata.data.gasLimit = '2100000';
			await web3.eth
				.sendTransaction(resdata.data)
				.on('receipt', receipt => {
					console.log(receipt);
					UpDate();

				})
				.on('error', error => {
					setIsload(false);
					if (ErrFun) {
						ErrFun(error);
					}
					console.log('error', error);
				});
			setIsload(false);
			if (ErrFun) {
				ErrFun('');
			}
			return { error: null };
		} else {
			setIsload(false);
			if (ErrFun) {
				ErrFun('profile error:' + resdata.data);
			}
			return { error: 'profile error:' + resdata.data };
		}
	}
	return { getProfile, update, register, build };
};

export const useShare = () => {
	const API = useApi();

	// 获取我的分享列表
	async function getShareList() {
		const data = await API.get('/share');
		return data;
	}

	// 得到一条共享记录 /share/info/{shareid}?password={password}
	async function getShare(shareId, password, setIsload) {
		if (password) password = '?password=' + password;
		const resdata = await API.get('/share/info/' + shareId + password);
		if (resdata.data) {
			setIsload(false);
			return resdata.data;
		} else {
			setIsload(false);
			return { error: 'shareInfo error:' + resdata };
		}
	}

	// 创建共享
	async function createShare(data, setIsload) {
		setIsload(true);
		const resdata = await API({
			method: 'POST',
			url: '/share',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		});
		if (resdata.data) {
			setIsload(false);
			return resdata.data.replace(/\/s\//g, '');
		} else {
			setIsload(false);
			return { error: 'shareInfo error:' + resdata.data };
		}
	}

	// 删除共享
	async function deleteShare(shareId, setIsload) {
		setIsload(true);
		const resdata = await API({
			method: 'DELETE',
			url: '/share/' + shareId
		});
		if (resdata.data) {
			setIsload(false);
			return resdata.data;
		} else {
			setIsload(false);
			return { error: 'shareInfo error:' + resdata.data };
		}
	}
	// 删除共享
	async function saveShare(shareId, password, setIsload) {
		setIsload(true);
		if (password) password = '?password=' + password;
		const resdata = await API({
			method: 'POST',
			url: '/save/' + shareId + password
		});
		if (resdata.data) {
			setIsload(false);
			return resdata.data;
		} else {
			setIsload(false);
			return { error: 'shareInfo error:' + resdata.data };
		}
	}

	return { getShareList, getShare, createShare, deleteShare, saveShare };
};

export const useNFT = () => {
	const web3 = useWeb3();
	const { chainId, account } = useWallet();
	const NFTContract = useMemo(() => (chainId && web3 ? getNFTContract(chainId, web3) : null), [chainId, web3]);
	// 创建NFT
	const CreateNFT = useCallback(
		async (data, Errfun) => {
			try {
				await NFTContract.methods.mint(data).send({ from: account });
				Errfun('');
				return '';
			} catch (e) {
				if (Errfun) {
					Errfun('create NFT Error');
				}
				console.warn('CreateNFT:', e);
				return 'create NFT Error';
			}
		},
		[NFTContract]
	);
	// 我的NFT列表
	async function MyNFTList(Errfun) {
		try {
			const list = await NFTContract.methods.tokensInfoOfOwner(account).call();
			const newlist = [];
			for (let index = 0; index < list.length; index++) {
				const row = JSON.parse(list[index].tokenURI);
				newlist.push({ tokenId: list[index].tokenId, data: row });
			}
			return newlist;
		} catch (e) {
			console.warn('MyNFTList:', e);
			if (Errfun) {
				Errfun('Get NFT List Error');
			}
			return [];
		}
	}
    // 获取token 基本信息
	async function tokenURI(tokenId, Errfun) {
		try {
			const row = await NFTContract.methods.tokenURI(tokenId).call();
			const newrow = JSON.parse(row);
			return newrow;
		} catch (e) {
			console.warn('MyNFTList:', e);
			if (Errfun) {
				Errfun('Get NFT List Error');
			}
			return {};
		}
	}

	return { CreateNFT, MyNFTList, tokenURI };
};
export const usePkg = () => {
	const web3 = useWeb3();
	const { chainId, account } = useWallet(); //chainId && 
	const PkgContract = useMemo(() => (web3 ? getClientHandAddrContract(web3) : null), [chainId, web3]);
	// 增加套餐
	const adminAddPkgInfo = useCallback(
					async (time,amount,kind,buysize,description, Errfun) => {
									try {
													await PkgContract.methods.adminAddPkgInfo(time,amount,kind,buysize,description).send({ from: account });
													Errfun('');
													return '';
									} catch (e) {
													if (Errfun) {
																	Errfun('adminAddPkgInfo Error');
													}
													console.warn('adminAddPkgInfo:', e);
													return 'adminAddPkgInfo Error';
									}
					},
					[PkgContract]
	);

	// 列出套餐
	async function storeGetPkgInfos(Errfun) {
					try {
									const list = await PkgContract.methods.storeGetPkgInfos().call();
/*                         const newlist = [];
									console.log('ALLNFTList:', list);
									for (let index = 0; index < list.length; index++) {
													const row = JSON.parse(list[index].tokenURI);
													newlist.push({ tokenId: list[index].tokenId, data: row });
									} */
									return list;
					} catch (e) {
									console.warn('storeGetPkgInfos:', e);
									if (Errfun) {
													Errfun('Get storeGetPkgInfos List Error');
									}
									return [];
					}
	}
	// 购买套餐
	async function buyPkg(pkgId,amount,starttime, Errfun) {
					try {
									await PkgContract.methods.buyPkg(pkgId,amount,starttime).send({ from: account });
									// const newrow = JSON.parse(row);
									return {};
					} catch (e) {
									console.warn('buyPkg:', e);
									if (Errfun) {
													Errfun('buyPkg Error');
									}
									return {};
					}
	}

	return {  adminAddPkgInfo, storeGetPkgInfos, buyPkg };
};
