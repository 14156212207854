import { useContext,useMemo } from 'react';
import { Context } from '@/contexts/AppState';
import axios from 'axios';


const useAppState = () => {
  const { storageBalance, setStorageBalance } = useContext(Context);
  return { storageBalance, setStorageBalance};
};

export const useToken = () => {
  const {  memoToken, setMemoToken, profile, setProfile, profiles, setProfiles ,disSize, setDisSize,preSize,setPreSize,receive, setReceive,lensclient, setLensclient,totalStorage,setTotalStorage,IsUpdateProfile,setIsUpdateProfile,apptype, setApptype,dataList,setDataList,sharingplugins,setSharingplugins,cryptoPunks,setCryptoPunks,touDi,setTouDi,groupId, setGroupId,applicationID, setApplicationID} = useContext(Context);
  return {  memoToken, setMemoToken, profile, setProfile, profiles, setProfiles,disSize, setDisSize,preSize,setPreSize,receive, setReceive,lensclient, setLensclient,totalStorage,setTotalStorage,IsUpdateProfile,setIsUpdateProfile,apptype, setApptype,dataList,setDataList,sharingplugins,setSharingplugins,cryptoPunks,setCryptoPunks,touDi,setTouDi,groupId, setGroupId,applicationID, setApplicationID};
};

export const useStorage = () => {
  const { storage, setStorage } = useContext(Context);
  return { storage, setStorage };
};

export const useGroupPath = () => {
  const { groupId } = useToken();
  return useMemo(() => {
    let grouppath='';
    if (groupId=='3') {
       grouppath='group3/';
    }
      return grouppath;
  }, [ groupId]);
};
export const useGroupPathRoute = () => {
  const { groupId } = useToken();
  return useMemo(() => {
    let grouppath='';
    if (groupId=='3') {
       grouppath='group/3/';
    }
      return grouppath;
  }, [ groupId]);
};
export const useApi = () => {
  const { memoToken,groupId } = useToken();
  const grouppath = useGroupPath();
  return useMemo(() => {
      return axios.create({
          baseURL: `${process.env.REACT_APP_URL}${grouppath}`,
          timeout: 7000000,
          headers: { 'Content-Type': 'application/json',
          Authorization: 'Bearer '+memoToken
          // LensToken: 'Bearer '+lensToken,
         }
      });
  }, [ memoToken, groupId]);
};
export const useProfileApi = () => {
	const { memoToken } = useToken();
	return useMemo(() => {
		return axios.create({
			baseURL:'https://api.mefs.io:10000/memo-profile/',
			timeout: 7000000,
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + memoToken
				// LensToken: 'Bearer '+lensToken,
			}
		});
	}, [memoToken]);
};


/*
export const uselensApi = (url) => {
  const {  memoToken } = useToken();
  return useMemo(() => {
      return axios.create({
          baseURL: url || 'https://api.lens.dev/',
          timeout: 7000000,
          headers: { 'Content-Type': 'application/json',
          Authorization: 'Bearer '+lensToken
          // LensToken: 'Bearer '+lensToken,
         }
      });
  }, [lensToken, memoToken]);
};
*/

export default useAppState;