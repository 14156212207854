import React,{useCallback,useEffect} from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space ,notification} from 'antd';
import type { MenuProps  } from 'antd';
import useMyWallet from '@/hooks/useMyWallet';
import { CHAIN_INFO } from '@/constants/chainInfo';
import useNetworkProvider from '@/hooks/useNetworkProvider';
import { CHAIN_IDS_TO_NAMES } from '@/constants/chains';
import { shortenFileMidWithRightChars } from '@/utils/index';
import eth from '@/assets/images/eth.png';
// import metis from '@/assets/images/metis.png';
import meter from '@/assets/images/meter.png';
import memo from '@/assets/images/MEMO.png';
import polygon from '@/assets/images/polygon.png';
// import totex from '@/assets/images/totex.png';
import BSC from '@/assets/images/2.png';
import Arbitrum from '@/assets/images/1.png';
import './index.scss';
import {useToken} from '@/hooks/useAppState';


function MultiChain() {
  const [api, contextHolder] = notification.useNotification();
  let { reset,connect,chainId,isConnected } = useMyWallet(); // chainId, status, reset,connect reset,
  // const [selectChainTitle,setSelectChainTitle] = useState(CHAIN_INFO[DefaultChainId].label || 'Polygon Mainnet');
  const { switchChain,selectChainId,setSelectChainId,selectChainTitle,setHaveswitch,visibility } = useNetworkProvider();
  const { apptype,applicationID}= useToken();
  const application = applicationID.replace(/ /g , '');

	const changNetWork = useCallback((id) => {
    // console.log('click', id);
		const tmpChainId = 1 * id;
		const chain = CHAIN_INFO[tmpChainId];
    if (!chain) return;
    // setSelectChainId(tmpChainId);
    // setSelectChainTitle(chain.label);
    // console.log(selectChainId);
    setHaveswitch(true);
		switchChain({
			chainId: '0x' + tmpChainId.toString(16),
			chainName: CHAIN_IDS_TO_NAMES[tmpChainId],
			nativeCurrency: chain.nativeCurrency,
			rpcUrls: chain.rpcUrls,
			explorerUrl: chain.explorer
		});
	}, []);
  // useEffect(() =>{
  //   const defaultChainId = '985';
  //   setSelectChainId(defaultChainId);
  // });

  useEffect(() => {
    if (!visibility) return;
    if (selectChainId==chainId) return;
    if(!isConnected) return;
   if (apptype=='tokenpocket') {
      reset();
      setHaveswitch(true);
      changNetWork(selectChainId);
      setTimeout(() => {
        connect('injected');
      }, 1000);
      return;
   }
    setHaveswitch(true);
    changNetWork(selectChainId);
  }, [selectChainId,visibility]);

  const items: MenuProps['items'] = [
    {
      label: 'Polygon Mainnet',
      key: 137,
      icon: <img src={polygon}/>
    },
    {
      label: 'Ethereum Mainnet',
      key: 1,
      icon: <img src={eth}/>
    },
    {
      label: 'Memo Megrez',
      key: 985,
      icon: <img src={memo}/>
    },
    // {
    //   label: 'zkSync',
    //   key: 324,
    //   icon: <img src={memo}/>
    // },
    {
      label: 'Meter Mainnet',
      key: 82,
      icon: <img src={meter}/>
    },
    {
      label: 'BSC Mainnet',
      key: 56,
      icon: <img src={BSC}/>
    },

    {
      label: 'Arbitrum',
      key: 42161,
      icon: <img src={Arbitrum}/>
    }
    // ,
    // {
    //   label: 'Metis Andromeda Mainnet',
    //   key: 1088,
    //   icon: <img src={metis}/>
    // },
    // {
    //   label: 'IoTeX Network Mainnet',
    //   key: 4689,
    //   icon: <img src={totex}/>
    // }
  ];
  useEffect(()=> {
    if (![137,1,985,82,1088,4689,42161,56].includes(chainId)) {
      openNotificationWithIcon('warning');
    } 
  },[chainId]);
  function isMobileDevice() {
    return window.matchMedia('(max-width: 767px)').matches;
  }
  useEffect(() => {
    if(application === 'webviewmetamaskmobile' && ['82','1088','4689','42161','56'].includes(selectChainId)){
      openNotificationWithIcon('warning');
    }
},[selectChainTitle]);

const openNotificationWithIcon = (type: 'warning') => {
  api[type]({
    message: 'Notification Title',
    description:
      'EthDrive does not support the chain network you have chosen. Please select a correct chain network.'
  });
};
  const menuProps = {
    items,
    onClick:(e) =>{ setSelectChainId(e.key); }
  };
  return (
    <div className="MultiChain">
      {contextHolder}
      {/* {apptype=='tokenpocket' && <span>{CHAIN_IDS_TO_NAMES[chainId]}</span>}
     {apptype!='tokenpocket' && <Dropdown menu={menuProps} */}
     <Dropdown menu={menuProps}
       trigger={['click']}>
      <Button>
        <Space>
          {items.find(value => value['key']==selectChainId)['icon']}
          {isMobileDevice ? shortenFileMidWithRightChars(selectChainTitle):{selectChainTitle}}
          <DownOutlined />
          {/* {application === ';bitkeepandroid/8.4.0' &&
            <div>{applicationID}</div>
          } */}
        </Space>
      </Button>
    </Dropdown>
  {/* } */}
    </div>
  );
}

export default MultiChain;
