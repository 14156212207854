import React from 'react';
import { Button, Col, Row } from 'antd';
import {useToken} from '@/hooks/useAppState';
import useMyWallet from '@/hooks/useMyWallet';
import { useTranslation } from 'react-i18next';
import statistics from '@/assets/images/statistics.png';
import './index.scss';


const UploadPage: React.FC = () => {
  const { t } = useTranslation();
  const wallet = useMyWallet();
  const {setMemoToken} = useToken();
  const showMo= () =>{
    // setLensdriveLoading(true);
    activate('injected');
  };
  const activate = (connector) => {
    setMemoToken('');
    wallet.reset();
    wallet.connect(connector);
  };
  return (
    <>
      <div className="UploadPage">
        <div className="container">
        <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} key="UploadCharacters">
          <div className="UploadCharacters">
            <h1>{t('Index.Home')}</h1>
            <p>{t('Index.Eth')}</p>
            <div className="UploadIcon">
              <Button onClick={showMo}>Get Started</Button>
              <a href="https://twitter.com/drive3_net" target="_blank" rel="noreferrer"><i className="iconfont icon-twitter"></i></a>
              <a href="https://t.me/drive3global" target="_blank" rel="noreferrer"><i className="iconfont icon-telegram"></i></a>
              <a href="https://github.com/drive3net" target="_blank" rel="noreferrer"><i className="iconfont icon-github"></i></a>
{/*               <a href="https://twitter.com/ethdrive_net" target="_blank" rel="noreferrer"><i className="iconfont icon-twitter"></i></a>
              <a href="https://t.me/ethdriveglobal" target="_blank" rel="noreferrer"><i className="iconfont icon-telegram"></i></a>
              <a href="https://github.com/ethdriveio/ethdriveui.git" target="_blank" rel="noreferrer"><i className="iconfont icon-github"></i></a> */}
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={10} lg={10} xl={10} key="UploadImg">
          <div className="UploadImg">
            <img src={statistics}/>
          </div>
        </Col>
        </Row>
        </div>
      </div>
    </>
  );
};

export default UploadPage;
