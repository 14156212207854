import React from 'react';
import {Avatar,Progress,Popover} from 'antd';
import { CloseOutlined ,ProfileOutlined} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useNetworkProvider from '@/hooks/useNetworkProvider';
// import LanguageSwitching from '@/components/Header/components/LanguageSwitching';
import sculpture from '@/assets/images/sculpture.png';
import {useToken} from '@/hooks/useAppState';
import useMyWallet from '@/hooks/useMyWallet';
import { shortenAddress } from '@/utils/index';

import './index.scss';

function RoleList() {
  const { preSize,disSize,profile}= useToken(); // lensToken, profile,
  const wallet = useMyWallet();
  const navigate = useNavigate();
  const {selectChainId} = useNetworkProvider();
  //const urlString = (profile?.picture?.original?.url) || '';
  //const url = urlString.replace(/:/g, '');
  const history = useNavigate();
  const { t } = useTranslation();
  function goBack() {
    history(-1);
 }
 //判断链，如果链是一下三点，就跳转
 const goAvatar =()=>{
  switch (1*selectChainId) {
    case 985:
      navigate('/Personal');
      break;
    case 137:
      navigate('/Personal');
      break;
    case 82:
      navigate('/Personal');
      break;
  }
 };
 const showModal = () => {
  wallet.reset();
  navigate('/');
};
const showRecharge = () => {
  navigate('/Introduce');
};
  return (
    <div className="RoleList">
      <div className="container">
        <div>
          <CloseOutlined onClick={goBack} />
        </div>
        <div className="Mobile_content">
          <div className="Avatar">
            <span >
            <Popover content={wallet.account}   trigger="hover">
              <Avatar size={36}
                src={profile['avatardata']? profile['avatardata'] : sculpture}
              />
            {shortenAddress(wallet.account)}
            </Popover>
            </span>
            <div className="Progress">
            <Progress
              strokeLinecap="butt"
              percent={preSize * 100}
              showInfo={false}
              format={() => disSize}
              style={{ height: '12px' }}
            />
              <div className="LeftList_Progress">
                <span>{disSize}</span>
              </div>
            </div>
          </div>
          {( selectChainId*1  == 82 || selectChainId*1  == 985) &&
                        <div className="Avatars" onClick={goAvatar}>
                        <p>
                          <ProfileOutlined />
                         {t('web.profiler')}
                        </p>
                      </div>
          }
          <div className="Avatars">
          <a href="https://ethdrive.net/docs/#/"  target="_blank" rel="noreferrer">
              <p >
              <span className="iconfont icon-dingdan"/>
                Docs
              </p>
              </a>
          </div>
          {/* <LanguageSwitching/> */}
          {(selectChainId*1  == 985) &&
          <div className="Avatars" onClick={showRecharge}>
            <p
             ><span className="iconfont icon-chongzhi"></span>Recharge
            </p>
          </div>
          }
          <div className="Avatars" onClick={showModal}>
            <p
             ><span className="iconfont icon-tuichu"></span>{t('web.Logout')}
            </p>
          </div>
          <div className="Avatars">
            <p
             >
              <a href="https://twitter.com/drive3_net" target="_blank" rel="noreferrer"><i className="iconfont icon-twitter"></i></a>
             <a href="https://t.me/drive3global" target="_blank" rel="noreferrer"><i className="iconfont icon-telegram"></i></a>
             <a href="https://github.com/drive3net" target="_blank" rel="noreferrer"><i className="iconfont icon-github"></i></a> 
             {/* <a href="https://twitter.com/ethdrive_net" target="_blank" rel="noreferrer"><i className="iconfont icon-twitter"></i></a>
             <a href="https://t.me/ethdriveglobal" target="_blank" rel="noreferrer"><i className="iconfont icon-telegram"></i></a>
             <a href="https://github.com/ethdriveio/ethdriveui.git" target="_blank" rel="noreferrer"><i className="iconfont icon-github"></i></a> */}
            </p>
          </div>
        </div>
    </div>
    </div>
  );
}

export default RoleList;
