import React ,{useEffect,useState}from 'react';
import { Progress, Button, Row, Col } from 'antd';
import { usePkg } from '@/hooks/useContract';
import { useToken ,useApi ,useStorage} from '@/hooks/useAppState';
import { useErc20 } from '@/hooks/useContract';
import useMyWallet from '@/hooks/useMyWallet';
import { ERC20Addr ,ClientHandlerAddr} from '@/constants/address';
import {formatBytes,simplifyNumber} from '@/utils/index';
import useNetworkProvider from '@/hooks/useNetworkProvider';
import './index.scss';

function Introduce() {
  const API = useApi();
  const {setStorage} = useStorage();
  const { preSize, disSize, totalStorage } = useToken(); // lensToken,
  const [CreateShareNft , setCreateShareNft] = useState([{amount:'',buysize:'',time:'',2:'',3:''}]);
  const { storeGetPkgInfos ,buyPkg} = usePkg();
  const wallet  = useMyWallet();
  const {selectChainId} = useNetworkProvider();
  const Erc20 = useErc20( ERC20Addr );
  // const pkgId = 'your_pkg_id';
  // const amount = 10;
  // function stringToBytesUTF8(str) {
  //   const encoder = new TextEncoder();
  //   return encoder.encode(str);
  // }
  //报错信息
  const errfun = async(err) => {
    if(selectChainId == 985){
      console.log('err',err);
    }
  };
  const getStorage = ()=>{
    try {
      API.get('mefs/storageinfo')
      .then(function (res){
        if (res && res.status == 200) {
              setStorage(res.data);
          } else {
            setStorage({});
          }
      }).catch(()=>{
        setStorage({});
      });
    } catch (error) {
      console.log(error);
      setStorage({});
    }
  };
useEffect(()=>{
  createShareNft();
},[selectChainId,totalStorage,disSize,preSize]);
  // async function main() {
  //   const time = 2;
  //   const amount = 1;
  //   const kind = 0;
  //   const buysize = 10737418240;
  //   // const bytes = '100G的套餐';
  //   // const myString = stringToBytesUTF8(bytes);
  //   const Errfun = (error) => {
  //     console.error(error);
  //   };
  //   try {
  //     const result = await adminAddPkgInfo(time,amount,kind,buysize,'0x', Errfun);
  //     console.log('result',result);
  //     // 在这里处理返回的结果
  //   } catch (error) {
  //     console.error('调用buyPkg函数时出错:', error);
  //     // 在这里处理错误
  //   }
  // }
const createShareNft = async() => {
  if(selectChainId == 985){
  try {
    const pkgInfos = await storeGetPkgInfos(errfun);
    // 处理返回的 pkgInfos 数据
    setCreateShareNft(pkgInfos);

    // 在这里执行你希望进行的操作
  } catch (error) {
    // 处理错误情况
    console.error('调用 storeGetPkgInfos 函数时出错:', error);
    // 在这里执行错误处理逻辑
  }
  // buyPkg(pkgId, amount, 0, errfun)
  // .then((newrow) => {
  //   // 处理成功返回的数据
  //   console.log(newrow);
  // })
  // .catch((error) => {
  //   // 处理错误情况
  //   console.error('buyPkg Error:', error);
  // });
}
};
const handleClickBuy = async(CreateShareNft,index)=>{
  await Erc20.methods.approve(ClientHandlerAddr, CreateShareNft.amount).send({ from: wallet.account });
  buyPkg(index, CreateShareNft.amount, 0, errfun)
  .then(() => {
    getStorage();
  })
  .catch((error) => {
    // 处理错误情况
    console.error('buyPkg Error:', error);
  });
};
  return (
    <div className="Introduce">
      <div className="container">
        <div className="Home">
          <div className="Introduce_left">
            <p className="space">Storage</p>
            <span className="Memory">
              <p>Used{disSize}</p>
              <p>Used:{totalStorage?totalStorage : '0B'}</p></span>
            <Progress
              strokeLinecap="butt"
              percent={preSize * 100}
              showInfo={false}
              format={() => disSize}
              style={{ height: '30px' }}
            />

            <p className="illustrate">Package</p>

          </div>
          <div className="Introduce_Right">
            {/* <div className="free">
              <p>免费赠送1247G超大空间</p>
              <span>
                <p>长期有效</p>
                <p>新用户专享</p>
                <Button>立即领取</Button>
              </span>
            </div> */}
            <Row  gutter={[16, 16]} style={{width:'100%'}}>
              <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={8}>
                <div className="freer">
                  <span className="span">{CreateShareNft[0] && CreateShareNft[0][2] ? formatBytes(CreateShareNft[0][2] , 1 ):'10G'}</span>
                  <p>By paying {CreateShareNft[0] &&CreateShareNft[0][3]?simplifyNumber(CreateShareNft[0][3]):'0.0000'}memo, you will obtain 10G of storage space for a duration of one year.</p>
                  <Button onClick={() =>handleClickBuy(CreateShareNft[0],1)}>Buy Now</Button>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={8}>
                <div className="free">
                  <span className="span">{CreateShareNft[1] &&CreateShareNft[1][2]?formatBytes(CreateShareNft[1][2] , 1 ):'100G'}</span>
                  <p>By paying {CreateShareNft[1] &&CreateShareNft[1][3]?simplifyNumber(CreateShareNft[1][3]):'1000.0000'}memo, you will obtain 100G of storage space for a duration of one year.</p>
                  <Button onClick={() =>handleClickBuy(CreateShareNft[1],2)}>Buy Now</Button>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} md={12} lg={8} xl={8}>
              <div className="fr">
                <span className="span">{CreateShareNft[2] &&CreateShareNft[2][2] ? formatBytes(CreateShareNft[2][2], 1):'500G'}</span>
                <p>By paying {CreateShareNft[2] &&CreateShareNft[2][3]?simplifyNumber(CreateShareNft[2][3]):'5000.0000'}memo, you will obtain 500G of storage space for a duration of one year.</p>
                <Button onClick={() =>handleClickBuy(CreateShareNft[2],3)}>Buy Now</Button>
              </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Introduce;
