import React ,{useState}from 'react';
// import { useNFT } from '@/hooks/useContract';
import { useNavigate } from 'react-router-dom';
import { Col, Row, Button, Table, notification} from 'antd';
import { useToken ,useGroupPath} from '@/hooks/useAppState';
import useMyWallet from '@/hooks/useMyWallet';
// import { CHAIN_IDS_TO_NAMES } from '@/constants/chains';
import { shortenAddress } from '@/utils/index';
import type { ColumnsType } from 'antd/es/table';
// import { NFTAddr } from '@/constants/address';
import './index.scss';

function NftDetail() {
  // const { CreateNFT} =useNFT(); // MyNFTList
  const history = useNavigate();
  const { cryptoPunks } = useToken();
  const grouppath = useGroupPath();
  const wallet = useMyWallet();
  const [imageType] = useState(['jpg', 'jpeg', 'png', 'gif', 'webp']);
  const [archiveType] = useState(['zip', 'rar', '7z','gz', 'bz2', 'xz' ,'gzip' , 'bz2' ,'xz']);
  const [documentType] = useState(['docx', 'xlsx', 'pptx', 'pdf', 'txt', 'rtf', 'odt', 'csv', 'html', 'md']);
  const [musicType] = useState(['mp3', 'wav', 'aac', 'flac', 'ogg', 'wma', 'm4a', 'mp4', 'mid']);
  const [api, contextHolder] = notification.useNotification();
  interface DataType {
    key: string;
    event: string;
    price: string;
    from: string;
    to: string;
    date:string;
  }
  const columns: ColumnsType<DataType> = [
    {
      title: 'Event',
      dataIndex: 'event',
      key: 'event'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from'
    },
    {
      title: 'To',
      key: 'to',
      dataIndex: 'to'
    },
    {
      title: 'Date',
      key: 'date',
      dataIndex: 'date',
      fixed: 'right'
    }
  ];
  const data: DataType[] = [
    {
      key: '1',
      event: '--',
      price: '--',
      from: '--',
      to: '',
      date:'--'
    }
  ];
    // 下载方法account
    const download = (items)=>{
      const link = document.createElement('a');
        link.href = `${process.env.REACT_APP_URL}${grouppath}mefs/public/${items}?chainid=${wallet.chainId}&address=${wallet.account}`;
      link.click();
  };
  const phoneCopy = items =>{
    const oInput = document.createElement('input');
    oInput.value = `${process.env.REACT_APP_URL}${grouppath}mefs/public/${items}?chainid=${wallet.chainId}&address=${wallet.account}`;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand('Copy'); // 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display = 'none';
    document.body.removeChild(oInput);
    api.open({
      message: 'Link copied successfully',
      type: 'info',
      duration: 0
    });
  };
  // const createNFTHandle=()=> {
  //   const data={
  //     url:'http://mefs.io:10000/',
  //     desc:'dfdfdfdf',
  //     name: 'rtty'
  //   };
  //   CreateNFT(JSON.stringify(data),null);
  // };
  function goBack() {
    history(-1);
  }
  return (
    <div className="NftDetail">
      {contextHolder}
      <div className="container">
        <div className="Home">
          <p className="iconfont icon-a-zuojiantouzhixiangzuojiantou" style={{ cursor: 'pointer', color: '#35445D', fontSize: '24px' }} onClick={goBack}></p>
          <Row className="Home_Personal">
            <Col xs={24} sm={12} md={12} lg={12} xl={12} className="NftDetailImg">
              <div>
                {/* <img src={process.env.REACT_APP_URL+'mefs/public/'+cryptoPunks.data.url+'?chainid='+ wallet.chainId} /> */}
                {
                  imageType.includes(cryptoPunks.data.filetype) &&
                  <img src={process.env.REACT_APP_URL + 'mefs/public/' + (cryptoPunks.data.mid === undefined ? cryptoPunks.data.urlname : cryptoPunks.data.mid) + '?chainid=' + wallet.chainId} />
                }
                {
                  archiveType.includes(cryptoPunks.data.filetype) &&
                  <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F8FA' }}>
                    <span className="iconfont icon-yasuobao1" style={{ fontSize: '70px', color: '#3E88FF', marginTop: '-100%' }} />
                  </div>
                }
                {
                  documentType.includes(cryptoPunks.data.filetype) &&
                  <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F8FA' }}>
                  <span className="iconfont icon-PDF" style={{ fontSize: '70px', color: '#DB4241',marginTop: '-100%' }} />
                  </div>
                }
                {
                  (musicType.includes(cryptoPunks.data.filetype)) &&
                  <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F8FA' }}>
                  <span className="iconfont icon-yinle" style={{ fontSize: '70px', color: '#3E88FF', marginTop: '-100%' }} />
                  </div>
                }
                {
                  ([''].includes(cryptoPunks.data.filetype)) &&
								  <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F8FA' }}>
                  <span className="iconfont icon-weizhiwenjian" style={{ fontSize: '70px', color: '#3E88FF', marginTop: '-100%' }} />
                  </div>
                }
                {
                  (![...imageType,...archiveType,...documentType,...musicType,''].includes(cryptoPunks.data.filetype)) &&
                  <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F6F8FA' }}>
                    <span  style={{ fontSize: '70px', color: '#3E88FF', marginTop: '-100%' }} />
                  </div>
                }
              </div>
              <article className="description">
                <p >Description</p>
                <span>
                {cryptoPunks.data.desc}
                </span>
              </article>
            </Col>
            <Col xs={24} sm={{ span: 11, offset: 1 }} md={{ span: 11, offset: 1 }} lg={{ span: 11, offset: 1 }} xl={{ span: 11, offset: 1 }} className="NftFile">
              <span className="custom-class">#{cryptoPunks.data.class}</span>
              {/* <p className="NftFileName">{cryptoPunks.data.filename}</p> */}
              <p className="NftFileName">{cryptoPunks.data.name}</p>
              {/* <span className="NftFileDetail">{cryptoPunks.data.time || '2023/7/19 17:59:37'}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{formatBytes(cryptoPunks.data.size || 95920,2)}</span> */}
              <span className="NftFileDetail">{shortenAddress(wallet.account)}</span>
              <div className="NftFileContract">
                <p>Current Price</p>
                <span>{cryptoPunks.data.price}&nbsp;{cryptoPunks.data.currency}</span>
                <Row gutter={[16, 16]}>
                  <Col span={12} >
                    <Button type="primary" size="large"
                      style={{lineHeight: 'normal'}}
                      onClick={() => download(cryptoPunks.data.mid)}
                      block>
                      Download
                    </Button>
                  </Col>
                  <Col span={12} onClick={() => phoneCopy(cryptoPunks.data.mid)}>
                    <Button size="large" 
                      style={{lineHeight: 'normal'}}
                      block>Copy Link
                    </Button>
                  </Col>
                </Row>
              </div>
              <span className="detail-text">Information</span>
              <div className="NftFileChain">
                <p><span>Contract Address</span><span>{shortenAddress('0xd895f9cb9fcBb6fC9fE2c1B1041E506B3247Bf09')}</span></p>
                <p><span>Token ID</span><span>{cryptoPunks.tokenId}</span></p>
                <p><span>Token Standard</span><span>ERC-721</span></p>
                <p><span>Chain</span>
                <span>Memo</span></p>
                <p><span>Last Updated</span><span>{cryptoPunks.data.time}</span></p>
                <p><span>Creator Earnings</span><span>0%</span></p>
              </div>
            </Col>
            <Col span={24}>
            <p className="item-activity">Item Activity</p>
            <Table columns={columns} dataSource={data}  bordered pagination={false}  />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default NftDetail;
