const key = '__LOCALE';

export const setLocale = (val) => {
  localStorage.setItem(key, val);
};

export const getLocale = () => {
  const locale = localStorage.getItem(key);
  return locale;
};

const groupkey = '__GROUPID';

export const setGroupIdLoc = (val) => {
  localStorage.setItem(groupkey, val);
};

export const getGroupIdLoc = () => {
  const locale = localStorage.getItem(groupkey);
  return locale;
};