import React, { createContext, useCallback,useEffect, useState } from 'react';

import { getLocale, setLocale as setLocaleString, setGroupIdLoc, getGroupIdLoc } from '@/constants/locales';
export const Context = createContext({applicationID:'',setApplicationID:null,locale: null, setLocale:null, storageBalance:null, setStorageBalance:null,touDi:{ID:'',Mid:'',ModTime:'',Name:'',Size:''},setTouDi:null,lensToken: '' ,setLensToken: null,memoToken: '',setMemoToken: null,storage: {Used:0, Free:0, Buysize:0, Files:0}, setStorage:null,profile: {name:'',avatar:'',register:false,id:'',picture:{original:{url:''}},bio:'',attributes:[{value:''}]}, setProfile:null,setSharingplugins:null,sharingplugins:[],setDataList:null,dataList:[],profiles: [{name:'',handle:'',id:'',picture:{original:{url:''}}, bio:'',attributes:[{value:''}]}], setProfiles:null,setTotalStorage:null,setDisSize:null,setPreSize:null,totalStorage:'',disSize:'',preSize:0,IsUpdateProfile:false,setIsUpdateProfile:null,receive:false, setReceive:null,lensclient:null, setLensclient:null,apptype:'', setApptype:null,cryptoPunks:{tokenId:'',data:{urlname:'',mid:'',desc:'',name:'',url:'',time:'',size:'',filetype:'',filename:'',currency:'',class:'',price:''}},setCryptoPunks:null,groupId:'1', setGroupId:null});

interface IProps {
  children: any
}

const AppStateContext: React.FC<IProps> = ({children}) => {
  const [locale, setLocale] = useState(getLocale());
  const [groupId, setGroupId] = useState(getGroupIdLoc());
 
  useEffect(() => {
    setGroupIdLoc(groupId);
  }, [groupId]);

  const handleActiveLocale = useCallback((val) => {
    setLocaleString(val);
    setLocale(val);
  }, [setLocale]);

  const [storageBalance, setStorageBalance] = useState(0);
  const [cryptoPunks,setCryptoPunks] = useState({tokenId:'',data:{urlname:'',mid:'',desc:'',name:'',url:'',time:'',size:'',filetype:'',filename:'',currency:'',class:'',price:''}});
  const [lensToken, setLensToken] = useState('');
  const [apptype, setApptype] = useState('');
  const [memoToken, setMemoToken] = useState('');
  const [touDi, setTouDi] = useState({ID:'',Mid:'',ModTime:'',Name:'',Size:''});
  const [profile, setProfile] = useState({name:'',register:false,avatar:'',id:'',picture:{original:{url:''}},bio:'',attributes:[{value:''}]});
  const [profiles, setProfiles] = useState([{name:'',handle:'',id:'',picture:{original:{url:''}},bio:'',attributes:[{value:''}]}]);
  const [storage, setStorage] = useState({Used:0, Free:0, Buysize:0, Files:0});
  const [disSize, setDisSize] = useState('');
  const [dataList, setDataList] = useState([]);
  const [sharingplugins,setSharingplugins] = useState([]);
  const [totalStorage, setTotalStorage] = useState('');
  const [receive, setReceive] = useState(false);
  const [IsUpdateProfile,setIsUpdateProfile] = useState(false);
  const [lensclient, setLensclient] = useState(null);
  const [applicationID, setApplicationID] = useState('');
  //空间内容多少
  const [preSize, setPreSize] = useState(0);
  return (
    <Context.Provider value={{ locale, setLocale: handleActiveLocale, storageBalance, setStorageBalance,touDi,setTouDi, lensToken, setLensToken, memoToken, setMemoToken,storage, setStorage, profile, setProfile,cryptoPunks,setCryptoPunks,totalStorage,setTotalStorage ,disSize, setDisSize,preSize,setPreSize,receive,IsUpdateProfile,setIsUpdateProfile, setReceive,profiles, setProfiles,lensclient, setLensclient,apptype, setApptype,dataList,setDataList,sharingplugins,setSharingplugins, groupId, setGroupId,applicationID, setApplicationID}}>
      {children}
    </Context.Provider>
  );
};

export default AppStateContext;