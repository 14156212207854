export const ERC20Addr = '0xeB8eec5a2dBf6e6f4Cc542ad31CCe706f8f80419';
export const ClientHandlerAddr = '0xa5596648E29620aa116013F46E580C9e841bE3E4';

export const NFTAddr = {
    985:'0xd895f9cb9fcBb6fC9fE2c1B1041E506B3247Bf09',
    137:'0x7A9589e4e630a73Eb918BA1c4a80f1F6eb7Ef291',
    1:'0x7A9589e4e630a73Eb918BA1c4a80f1F6eb7Ef291',
    1088:'0x7A9589e4e630a73Eb918BA1c4a80f1F6eb7Ef291',
    82:'0x300016d8129Aad2bc0EefD1A85fC0e8E6f538502',
    4689:'0x7A9589e4e630a73Eb918BA1c4a80f1F6eb7Ef291'
    // 324:'0x7A9589e4e630a73Eb918BA1c4a80f1F6eb7Ef291'
};