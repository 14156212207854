import React, { useCallback, useEffect } from 'react';
// import NetworkSelector from '@/components/Header/components/NetworkSelector';
import { useNavigate , useLocation} from 'react-router-dom';
// import Web3Status from '@/components/Header/components/Web3Status';
import { Button } from 'antd';
import UserAvatar from '@/components/Header/components/UserAvatar';
import MultiChain from '@/components/Header/components/MultiChain';
import useMyWallet from '@/hooks/useMyWallet';
import { useToken } from '@/hooks/useAppState';
// import RechargeUpload from '@/components/Header/components/RechargeUpload';
// import LanguageSwitching from '@/components/Header/components/LanguageSwitching';
import logo from '@/assets/images/logo.png';
import MobileLogo from '@/assets/images/MobileLogo.png';
// import { Post } from '@/lens/api'; 
// import { useTranslation } from 'react-i18next';
import './index.scss';

const HeaderPage: React.FC = () => {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const {memoToken,setMemoToken} = useToken();
  const location = useLocation();
  const currentPath = location.pathname;

  //type接口




  // const goMessage = () => {
  //   navigate('/Message');
  // };
  // const goIntroduce = () => {
  //   navigate('/Introduce');
  // };
  // const goOrderGoods = () =>{
  //   navigate('/OrderGoods');
  // };
  const goHone = () => {
    navigate('/');
  };
  const goMintNFT = () => {
    if(currentPath == '/'){
      return;
    }
    navigate('/');
  };
  const goNFTCreate = () => {
    if(currentPath == '/MintNFT'){
      return;
    }
    navigate('MintNFT');
  };
  // const goSharing = () =>{
  //   navigate('Sharing');
  // };

  const wallet = useMyWallet();
  const { receive, setReceive ,setApplicationID} = useToken(); // lensToken ,lensclient 
  const ua = navigator.userAgent.toLowerCase();
  useEffect(() => {
    if (wallet.status === 'connected') {
      setReceive(getLocalValue('Receive' + wallet.account));
    }
    const regex = /537\.36(?!.*537\.36)(.*)$/;
    const match = ua.match(regex);
    if (match && match[1]) {
      const trimmedMatch = match[1].trim();
      setApplicationID(trimmedMatch);
    }
  }, [wallet.account, receive]);

  useEffect(() => {
    const interval = setInterval(() => {
      setMemoToken('');
    }, 600000); // 每分钟执行一次

    return () => {
      clearInterval(interval);
    };
  }, []);
  // const setLocalValue = (key, value)=> {
  //     if (window && window.localStorage) {
  //       try {
  //        window.localStorage.setItem(key, JSON.stringify(value));
  //        setReceive(true);
  //       } catch (err) {
  //          console.log(err);
  //         setReceive(false);
  //      }
  //     }
  //   };

  const getLocalValue = useCallback((key) => {
    if (window && window.localStorage) {
      if (!window.localStorage.getItem(key)) {
        return false;
      }
      try {
        return JSON.parse(window.localStorage.getItem(key));
      } catch (err) {
        return false;
      }
    } else {
      return false;
    }
  }, [receive]);

  return (
    <>
      {/* {
      ( (!getLocalValue('Receive'+wallet.account)) && profile?.register && memoToken && wallet.status === 'connected')?
          <div className="Header">Please check to distribute 10.0G space to your account !
            <Button type="primary" shape="round"  onClick={() => setLocalValue('Receive'+wallet.account,true)}>
            Receive
            </Button>
          </div>
      : ''
    } */}
      <div className="HeaderPage">
        <div className="head" style={(!memoToken ) ? {} : {backgroundColor: '#fff',borderBottom:'1px solid rgba(229, 229, 229, 1)'}}>
          <div className="container">
            <div className="head-left">
              <div onClick={goHone}><img src={logo} /><img src={MobileLogo} /></div>
              <div className="p">
                {/* <span onClick={goSharing}>Files</span> */}
                {((wallet.chainId == 985||wallet.chainId == 82 ) && (wallet.status == 'connected' && memoToken) ) &&
                  <div>
                    <Button type="primary" className={(currentPath !== '/' && currentPath === '/MintNFT') ? '' : 'files'} onClick={goMintNFT} ghost>
                      Files
                    </Button>
                    <Button type="primary" className={(currentPath !== '/MintNFT') ? '' : 'files'} onClick={goNFTCreate}  ghost>
                      NFT
                    </Button>
                  </div>
                }
              </div>
              {/* <ul>

                <li onClick={goHome}
                  className="Top"
                >{t('Upload')}</li>
                <li
                  onClick={goRecharge}
                  className="Files"
                >{t('Top-up')}</li>
              </ul> */}
            </div>
            <div className="head-right">
              {/* <RechargeUpload /> */}
              {/* <NetworkSelector/> */}
              {/* <Web3Status /> */}
              <a href="https://ethdrive.net/docs/#/"  target="_blank" rel="noreferrer">
              <div className="headIconfont">
                Docs
              </div>
              <div className="headIcon">
                Docs
              </div>
              </a>

              <MultiChain />

              {/* 条件判断是否弹出列表 */}
              {/* {( wallet.status === 'connected' || !memoToken) &&
              <div className="head_collect">
                <p onClick={goIntroduce} className="iconfont icon-lingdang"></p>
                <p onClick={goOrderGoods} className="iconfont icon-kongjianfenxi"></p>
                <p onClick={goMessage} className="iconfont icon-dingdan"></p>
              </div>
              } */}
              <UserAvatar />
              {/* <LanguageSwitching/> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderPage;