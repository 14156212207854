import React, { useEffect, useState, useRef } from 'react';
import './index.scss';
import { Input, Button, Form, Avatar, notification, Select, Modal, Spin, Row, Col } from 'antd';
import { formatBytes } from '@/utils/index';
import { useNavigate } from 'react-router-dom';
import type { FormInstance } from 'antd/es/form';
import { useApi, useToken } from '@/hooks/useAppState';
import { useNFT } from '@/hooks/useContract';
import useNetworkProvider from '@/hooks/useNetworkProvider';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import NFTCreate from '@/assets/images/NFTCreate.png';
const { TextArea } = Input;

function NftCreate() {
  const [api, contextHolder] = notification.useNotification();
  const { selectChainId } = useNetworkProvider();
  const [isload, setIsload] = useState(false);
  const [isloadButton, setIsloadButton] = useState(false);
  const { CreateNFT } = useNFT();

  const [preview, setPreview] = useState(null);
  const formRef = React.useRef<FormInstance>(null);
  const { profile, touDi, setTouDi ,setCryptoPunks} = useToken();
  const [filetype, setFiletype] = useState('');
  const [currency, setCurrency] = useState('MEMO');
  const [display, setDisplay] = useState('');
  const [storage, setStorage] = useState('');
  const [filename, setFilename] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageType] = useState(['jpg', 'jpeg', 'png', 'gif', 'webp']);
  const [archiveType] = useState(['zip', 'rar', '7z','gz', 'bz2', 'xz' ,'gzip' , 'bz2' ,'xz']);
  const [documentType] = useState(['docx', 'xlsx', 'pptx', 'pdf', 'txt', 'rtf', 'odt', 'csv', 'html', 'md']);
  const [musicType] = useState(['mp3', 'wav', 'aac', 'flac', 'ogg', 'wma', 'm4a', 'mp4', 'mid']);
  const { Option } = Select;
  const history = useNavigate();
  const fileInput = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const API = useApi();

  const randomNum = Math.floor(Math.random() * 1000000); // 生成一个介于0和1000000之间的随机整数
  const Errfun = (err) => {
    if (err != '') {
      api['error']({
        message: 'Error',
        description: <p style={{ fontFamily: 'Roboto-regular' }}>{t('NFTCreate.')}{err}</p>
      });
      setIsModalOpen(false);
    }
  };
  //获取首页列表传输过来的数据
  useEffect(() => {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp','.webp'];
    const fileExtension = touDi.Name.toLowerCase().split('.').pop();
    const fileName = `${randomNum}_${touDi.Name}`; // 附加随机数到文件名中
    setFilename(fileName);
    setFiletype(fileExtension);
    setStorage(formatBytes(touDi.Size, 2));
    if (imageExtensions.includes('.' + fileExtension)) {
      setDisplay(`${process.env.REACT_APP_URL}mefs/public/${touDi.Mid}?chainid=${selectChainId}`);
    }
  }, [touDi.Name]);


  const createNFTHandle = async () => {
    const usernameValue = formRef.current.getFieldValue('name');
    const descriptionValue = formRef.current.getFieldValue('description');
    let classificationValue = formRef.current.getFieldValue('Classification');
    if (classificationValue == null) {
      classificationValue = 'NFT';
    }
    const Price = formRef.current.getFieldValue('Price');
    const date = new Date();
    const dateString = date.toLocaleDateString(); // 获取本地化的日期字符串，例如 "2023/07/19"
    const timeString = date.toLocaleTimeString(); // 获取本地化的时间字符串，例如 "14:30:00"
    const dateTimeString = dateString + ' ' + timeString; // 合并日期和时间字符串，例如 "2023/07/19 14:30:00" 
    // NFT 属性自己定义
    const data = {
      dataurl: 'https://ethdrive.net:9001/mefs/public/' + touDi.Mid + '?chainid=985',
      mid: touDi.Mid,
      desc: descriptionValue,
      name: usernameValue,
      time: dateTimeString,
      class: classificationValue,
      price: Price,
      currency: currency,
      size: storage,
      filetype: filetype,
      filename: filename,
      exturl: ''
    };
    const result = await CreateNFT(JSON.stringify(data), Errfun);
    if (result === '') {
      api['success']({
        message: 'Success',
        duration: 12,
        description:
          <p style={{ fontFamily: 'Roboto-regular' }}>{t('NFTCreate.Successfully')}</p>
      });
      clearLocalStorage();
      setIsload(true);
    }
    setCryptoPunks({data});
  };
  //文件上传判断
  const handleInputChange = (event) => {
    const files = event.target.files;
    if (files.length === 0) {
      return; // 文件为空，直接返回
    }
    setStorage(files[0].size);
    if (files.length > 0) {
      handleUpload(files[0]);
    }
  };
  function getFileExtension(fileName) {
    const dotIndex = fileName.lastIndexOf('.');
    if (dotIndex !== -1) {
      return fileName.substring(dotIndex + 1).toLowerCase();
    }
    return null;
  }
  function handleUpload(file) {
    setFilename(file.name);
    if (file.size >= 500 * 1024 * 1024) {
      api.open({
        message: 'Upload Error',
        type: 'error',
        description: `${t('Home.File_size')}`,
        duration: 0
      });

      return; // 中断函数执行
    }
    setFiletype(getFileExtension(file.name));
    setIsloadButton(true);
    const formData = new FormData();
    const fileName = `${randomNum}_${file.name}`; // 附加随机数到文件名中
    formData.append('file', file, fileName); // 将附加了随机数的文件名添加到formData对象中
    formData.append('public', 'true');
    setPreview(URL.createObjectURL(file));
    API({
      method: 'POST',
      url: 'https://api.mefs.io:10000/mefs/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data: formData
    }).then(function (res) {
      if (res.status == 200) {
        const mid = res.data.Mid;
        handleTouDiChange(mid);
        // message.success(`${t('Home.upload_modify')}`);
        api['success']({
          message: 'Success',
          duration: 12,
          description:
            <p style={{ fontFamily: 'Roboto-regular' }}>{t('Home.upload_modify')}</p>
        });
        setIsloadButton(false);
      } else {
        // message.error(`${t('Home.upload_download')}`);
        api['error']({
          message: 'Error',
          duration: 12,
          description:
            <p style={{ fontFamily: 'Roboto-regular' }}>{t('Home.upload_download')}</p>
        });
        setIsloadButton(false);
      }
    });
  }
  //将列表的mid传给toudi里的mid
  const handleTouDiChange = (mid) => {
    setTouDi({ ...touDi, Mid: mid });
  };
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 }
  };
  //表单验证
  const onFinish = (values: any) => {
    values.avatar = touDi.Mid || profile.avatar;
    setIsModalOpen(true);
    setIsload(false);
    createNFTHandle();
  };
  const validateMessages = {
    required: '${label} cannot be empty.'
  };
  function goBack() {
    history(-1);
  }
  //获取里的值
  const handleSelectChange = (e) => {
    setCurrency(e);
  };
  //清空缓存
  const clearLocalStorage =() => {
    setTouDi({ ...touDi, Mid: '' });
  };
  //关闭上传成功弹窗
  const handleOk = () => {
    setFiletype('');
    setIsModalOpen(false);
  };
  const goIndex = () => {
    setFiletype('');
    navigate('/NFTDetail');
  };
  //添加货币
  const selectAfter = (
    <Select defaultValue="MEMO"  style={{ borderRadius: '10px 10px 10px 10px'}} onChange={handleSelectChange}>
      <Option value="MEMO">MEMO</Option>
      <Option value="ETH">ETH</Option>
    </Select>
  );
  return (
    <div className="NftCreate">
      {contextHolder}
      <div className="container">
        <div className="Home">
          <p style={{ cursor: 'pointer', color: '#35445D', fontSize: '24px' }} onClick={goBack}>Mint NFT</p>
          <div className="NftCreate_Personal">
            <Form
              {...layout}
              ref={formRef}
              name="control-ref"
              onFinish={onFinish}
              style={{ maxWidth: '100%' }}
              validateMessages={validateMessages}
            >
              <span>{t('NFTCreate.Image')}</span>
              {/* <span>File upload</span> */}
              <p>{t('NFTCreate.File')}</p>
              {/* <p>Max size:500MB</p> */}
              <Form.Item label="The uploaded file"  rules={[{ required: true }]} initialValue={touDi.Mid !== '' ? touDi.Mid : undefined}  name="upload">
              <div className="file-input-container ">
                <div className="file-input-file">
                  <i className="iconfont icon-bianji" />
                  <input type="file" onChange={handleInputChange} ref={fileInput} />
                </div>
                <label className="file-input-label" >
                </label>
                {(imageType.includes(filetype)) &&
                  <Avatar onClick={() => fileInput.current.click()} shape="square" size={200} src={preview || display || NFTCreate}
                  />
                 }
                  {(archiveType.includes(filetype)) &&
                  <Avatar className="iconfont icon-yasuobao1" style={{ backgroundColor: '#F6F8FA', color: '#3E88FF', fontSize: '40px' ,objectFit: 'contain'}} size={200} onClick={() => fileInput.current.click()} shape="square"
                  />
                  }
                  {
                  (documentType.includes(filetype)) &&
                  <Avatar className="iconfont icon-PDF" style={{ backgroundColor: '#F6F8FA', color: '#DB4241', fontSize: '40px' ,objectFit: 'contain'}} size={200} onClick={() => fileInput.current.click()} shape="square"
                  />
                 }
                 {
                  (musicType.includes(filetype)) &&
                  <Avatar className="iconfont icon-yinle" style={{ backgroundColor: '#F6F8FA', color: '#3E88FF', fontSize: '40px' ,objectFit: 'contain'}} size={200} onClick={() => fileInput.current.click()} shape="square"
                  />
                }
                {
                  (!filetype) &&
                  <Avatar onClick={() => fileInput.current.click()} shape="square" size={200} src={preview || display || NFTCreate}
                  />
                }
                {
                   (![...imageType,...archiveType,...documentType,...musicType,''].includes(filetype)) &&
                   <Avatar className="iconfont icon-weizhiwenjian" style={{ backgroundColor: '#F6F8FA', color: '#3E88FF', fontSize: '40px' ,objectFit: 'contain'}} size={200} onClick={() => fileInput.current.click()} shape="square"
                   />
                }
              </div>
              </Form.Item>
              <Form.Item label={t('NFTCreate.Collection')}
                name="name"
                rules={[{ required: true }]} >
                <Input style={{ borderRadius: '10px' }} />
              </Form.Item>
              <span>{t('NFTCreate.Descripti')}</span>
              <p>{t('NFTCreate.description')}</p>
              <Form.Item name="description"
                rules={[{ required: true, message: 'Description cannot be empty' }]}
              >
                <TextArea style={{ borderRadius: '10px'}} rows={4} />
              </Form.Item>
              <Form.Item name="Classification" label="Class">
                <Select
                  style={{ borderRadius: '10px'}}
                  defaultValue="NFT"
                  options={[
                    { value: 'NFT', label: 'NFT' },
                    { value: 'Document', label: 'Document' },
                    { value: 'Media', label: 'Media' },
                    { value: 'Data', label: 'Data' },
                    { value: 'Video', label: 'Video' }
                  ]}
                />
              </Form.Item>
              <Form.Item
                label="Price"
                rules={[{ required: true, message: 'Cannot be empty, please enter the quantity of virtual currency.' }]}
                className="Price" name="Price">
                <Input style={{ borderRadius: '10px 10px 10px 10px'}} addonAfter={selectAfter} type="number" />
              </Form.Item>
              <Form.Item className="FormButton">
                <Button type="primary" htmlType="submit" loading={isloadButton} >
                  {t('NFTCreate.Create')}
                </Button>
              </Form.Item>
            </Form>
            <Modal open={isModalOpen}
              closeIcon={null}
              footer={null}>
              {!isload ?
              <div className="PageLoader">
                <p>Please wait...</p>
                <div className="my-elementLoading">
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                </div>
              </div>
              :
              <div className="elementLoadingDiv">
                <div className="my-elementLoading">
                {(imageType.includes(filetype)) &&
                  <Avatar onClick={() => fileInput.current.click()} shape="square" size={200} src={preview || display || NFTCreate}
                  />
                }
                {
                  (archiveType.includes(filetype)) &&
                  <Avatar className="iconfont icon-yasuobao1" style={{ backgroundColor: '#F6F8FA', color: '#3E88FF', fontSize: '40px' ,objectFit: 'contain'}} size={200} onClick={() => fileInput.current.click()} shape="square"
                  />
                }
                {
                  (documentType.includes(filetype)) &&
                  <Avatar className="iconfont icon-PDF" style={{ backgroundColor: '#F6F8FA', color: '#DB4241', fontSize: '40px' ,objectFit: 'contain'}} size={200} onClick={() => fileInput.current.click()} shape="square"
                  />
                }
                {
                  (musicType.includes(filetype)) &&
                  <Avatar className="iconfont icon-yinle" style={{ backgroundColor: '#F6F8FA', color: '#3E88FF', fontSize: '40px' ,objectFit: 'contain'}} size={200} onClick={() => fileInput.current.click()} shape="square"
                  />
                }
                {
                  (!filetype) &&
                  <Avatar className="iconfont icon-weizhiwenjian" style={{ backgroundColor: '#F6F8FA', color: '#3E88FF', fontSize: '40px' ,objectFit: 'contain'}} size={200} onClick={() => fileInput.current.click()} shape="square"
                  />
                }
                {
                  (![...imageType,...archiveType,...documentType,...musicType,''].includes(filetype)) &&
                  <Avatar onClick={() => fileInput.current.click()} shape="square" size={200} src={preview || display || NFTCreate}
                  />
                } 
                </div>
                <p>NFT casting has been completed, you can go to the data market to check.</p>
                <Row gutter={16}>
                  <Col className="gutter-row" span={12}>
                    <Button type="dashed" onClick={handleOk}  block>
                      Close
                    </Button>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Button type="primary" onClick={goIndex} block>
                      Go to Check
                    </Button>
                  </Col>
                </Row>
              </div>
              }
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NftCreate;
