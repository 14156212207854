
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import {useApi,useToken} from '@/hooks/useAppState';
import useMyWallet from '@/hooks/useMyWallet';
import './index.scss';
const dataSource = [
  {
    key: '1',
    name: '充值100memo',
    age: '永久有限',
    address: '2023-04-28',
    add:'--'
  },
  {
    key: '2',
    name: '200GB套餐',
    age: '永久有限',
    address: '2023-04-28',
    add:'--'
  },
  {
    key: '3',
    name: '200GB套餐',
    age: '永久有限',
    address: '2023-04-28',
    add:'--'
  }
];

const columns = [
  {
    title: '消费类型',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: '有效期',
    dataIndex: 'age',
    key: 'age'
  },
  {
    title: '下单时间',
    dataIndex: 'address',
    key: 'address'
  }
  ,
  {
    title: '操作',
    dataIndex: 'add',
    key: 'add'
  }
];
// 套餐
function OrderGoods() {
  const [pkginfos, setPkginfos] = useState([]);
  const [buypkgs, setBuypkgs] = useState([]);

  const {account, status } = useMyWallet();
  const {memoToken}= useToken(); //profile
  const API = useApi();

  useEffect(() => {

    if (memoToken && status === 'connected') {
     // 系统同配置的套餐
      API.get('account/pkginfos')
      .then(function (res){
        if (res && res.status == 200) {
              console.log('同配置的套餐套餐',res.data);
              setPkginfos(res.data);
          } else {
            setPkginfos([]);
          }
          console.log(pkginfos);
      });

      // 用户购买套餐
      API.get('account/getbuypkgs')
      .then(function (res){
        if (res && res.status == 200) {
          setBuypkgs(res.data);
          console.log('用户套餐',res.data);
          } else {
            setBuypkgs([]);
          }
      });

     console.log(buypkgs);

    } else {
      setPkginfos([]);
      setBuypkgs([]);
    }
  }, [memoToken,status,account]);


  return (
    <div className="OrderGoods">
      <div className="container">
        <div className="Home">
          <p>订单记录</p>
          <Table dataSource={dataSource} columns={columns} />
        </div>
      </div>
    </div>
  );
}

export default OrderGoods;
