import React, { useState ,useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import { Button } from 'antd';
import {useToken} from '@/hooks/useAppState';
import { useNFT } from '@/hooks/useContract';
import useMyWallet from '@/hooks/useMyWallet';

import './index.scss';

function NftList() {
	const navigate = useNavigate();
	const {setCryptoPunks,memoToken}= useToken();
	const [NFTlist,setNFTlist] = useState([]);
	const history = useNavigate();
	const wallet  = useMyWallet();
	const [imageType] = useState(['jpg', 'jpeg', 'png', 'gif', 'webp']);
  const [archiveType] = useState(['zip', 'rar', '7z','gz', 'bz2', 'xz' ,'gzip' , 'bz2' ,'xz']);
  const [documentType] = useState(['docx', 'xlsx', 'pptx', 'pdf', 'txt', 'rtf', 'odt', 'csv', 'html', 'md']);
  const [musicType] = useState(['mp3', 'wav', 'aac', 'flac', 'ogg', 'wma', 'm4a', 'mp4', 'mid']);
	const NFTDetail = (item) => {
		navigate('/NFTDetail');
		setCryptoPunks(item);
	};
  const goStorage = () =>{
    navigate('/NFTCreate');
  };
	const { MyNFTList } = useNFT();
	useEffect(() => {
		NFTListHandle();
	}, [memoToken,wallet.chainId]);

  const NFTListHandle = async () => {
	  let list = await	MyNFTList(null);
		setNFTlist(list);
	};
  function goBack() {
    history(-1);
  }
	return (
		<div className="NftList">
			<div className="container">
				<div className="Home">
					<div className="iconNft">
					<p style={{cursor: 'pointer',color:'#35445D',fontSize: '24px'}} onClick={goBack}>NFT List</p>
					<Button type="primary" shape="round" style={{ width: '120px', height: '40px'}} className="add-button" onClick={goStorage}>Create NFT</Button>
					</div>
					<div className="Home_Personal">
					{NFTlist != undefined && NFTlist.length != 0 ?
					<Row gutter={[16, 16]}>
					 {NFTlist
      .sort((a, b) => Number(b.tokenId) - Number(a.tokenId)) // 根据tokenId排序
      .map((item) => {
						return(
							<Col xs={24} sm={12} md={8} lg={6} xl={6} key={item.tokenId} onClick={() =>NFTDetail(item)}>
								<div className="image-wrapper" >
								{/* <img src={process.env.REACT_APP_URL+'mefs/public/'+item.data.url+'?chainid='+ wallet.chainId} /> */}
								{
								(imageType.includes(item.data.filetype) && item.data.size < 1048576) &&
								 <img  src={process.env.REACT_APP_URL+'mefs/public/'+(item.data.mid === undefined?item.data.urlname:item.data.mid)+'?chainid='+ wallet.chainId} />
								}
								{
								(imageType.includes(item.data.filetype) && item.data.size > 1048576) &&
								<div style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#F6F8FA'}}>
									<div className="iconfont icon-shangchuantupian" style={{fontSize:'70px',color:'#3E88FF'}}/>
								</div>
								}
								{
								(archiveType.includes(item.data.filetype)) &&
								<div style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#F6F8FA'}}>
									<div className="iconfont icon-yasuobao1" style={{fontSize:'70px',color:'#3E88FF'}}/>
								</div>
								}
								{
								(documentType.includes(item.data.filetype))&&
								<div style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#F6F8FA'}}>
									<div className="iconfont icon-PDF" style={{fontSize:'70px',color:'#DB4241'}}/>
								</div>
								}
								{
								(musicType.includes(item.data.filetype)) &&
								<div style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#F6F8FA'}}>
									<div className="iconfont icon-yinle" style={{fontSize:'70px',color:'#3E88FF'}}/>
								</div>
								}
								{
								(!item.data.filetype) &&
								<div style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#F6F8FA'}}>
									<div className="iconfont icon-weizhiwenjian" style={{fontSize:'70px',color:'#3E88FF'}}/>
								</div>
								}
								{
								(![...imageType,...archiveType,...documentType,...musicType,''].includes(item.data.filetype)) &&
								<div style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%',display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'#F6F8FA'}}>
									<div  style={{fontSize:'70px',color:'#3E88FF'}}/>
								</div>
								}
								</div>
								<div className="wrapperP">
									<p style={{ fontSize: '15px', whiteSpace: 'nowrap',overflow: 'hidden',textOverflow:'ellipsis',wordSpacing: '-2px'}}>{item.data.name}</p>
									<p className="Details">{item.data.price}&nbsp;&nbsp;{item.data.currency}</p>
									<p className="Details">
										<span>#{item.data.class}</span>
										{/* <Popover content={item.data.desc}  trigger="hover">{shortenFileMidWithRightChars(`${item.data.desc}`)}</Popover> */}
									</p>
								</div>
							</Col>
						);
					},
					)}
					</Row>
					:
					<div className="empty">
						<span>Your NFTs will be shown here.</span>
					</div>
					}
						{/* <Row>
							<Col span={6}
  							onClick={NFTDetail}
							>
								<img src={NFT} />
								<div>
									<p>Computer</p>
									<p>computer,work,blockchain</p>
								</div>
							</Col>
							<Col span={6}>
								<img src={NFT} />
								<div>
									<p>Computer</p>
									<p>computer,work,blockchain</p>
								</div>
							</Col>
							<Col span={6}>
								<img src={NFT} />
								<div>
									<p>Computer</p>
									<p>computer,work,blockchain</p>
								</div>
							</Col>
							<Col span={6}>
								<img src={NFT} />
								<div>
									<p>Computer</p>
									<p>computer,work,blockchain</p>
								</div>
							</Col>
							<Col span={6}>
								<img src={NFT} />
								<div>
									<p>Computer</p>
									<p>computer,work,blockchain</p>
								</div>
							</Col>
						</Row> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default NftList;
