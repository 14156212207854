import { SupportedChainId } from './chains';
export const NetworkType = {
  L1: 0,
  L2: 1
};

export const CHAIN_INFO = {
  [SupportedChainId.ETH]: {
    networkType: NetworkType.L1,
    explorer: 'hhttps://etherscan.io',
    label: 'Ethereum Mainnet',
    rpcUrls: ['https://ethereum.publicnode.com'],
    logoUrl: '',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18
    }
  },
  [SupportedChainId.MATIC]: {
    networkType: NetworkType.L2,
    explorer: 'https://polygonscan.com/',
    label: 'Polygon Mainnet',
    rpcUrls: ['https://polygon-bor.publicnode.com'],
    logoUrl: '',
    nativeCurrency: {
      name: 'Polygon Matic',
      symbol: 'MATIC',
      decimals: 18
    }
  },
  // [SupportedChainId.zkSync]: {
  //   networkType: NetworkType.L2,
  //   explorer: 'https://polygonscan.com/',
  //   label: 'zkSync Era Mainnet',
  //   rpcUrls: ['https://mainnet.era.zksync.io'],
  //   logoUrl: '',
  //   nativeCurrency: {
  //     name: 'zkSync',
  //     symbol: 'ETH',
  //     decimals: 18
  //   }
  // },
  [SupportedChainId.METER]: {
    networkType: NetworkType.L1,
    explorer: 'https://scan.meter.io',
    label: 'Meter Mainnet',
    rpcUrls: ['https://rpc.meter.io'],
    logoUrl: '',
    nativeCurrency: {
      name: 'MTR',
      symbol: 'MTR',
      decimals: 18
    }
  },
  [SupportedChainId.METIS]: {
    networkType: NetworkType.L2,
    explorer: 'https://andromeda-explorer.metis.io',
    label: 'Metis Andromeda Mainnet',
    rpcUrls: ['https://andromeda.metis.io/?owner=1088'],
    logoUrl: '',
    nativeCurrency: {
      name: 'METIS',
      symbol: 'METIS',
      decimals: 18
    }
  },
  [SupportedChainId.IOTX]: {
    networkType: NetworkType.L1,
    explorer: 'https://iotexscan.io',
    label: 'IoTeX Network Mainnet',
    rpcUrls: ['https://iotex-mainnet.gateway.pokt.network/v1/lb/6176f902e19001003499f492'],
    logoUrl: '',
    nativeCurrency: {
      name: 'IOTX',
      symbol: 'IOTX',
      decimals: 18
    }
  },
  [SupportedChainId.MATIC_TEST]: {
    networkType: NetworkType.L2,
    explorer: '',
    label: 'Polygon Testnet',
    rpcUrls: ['https://rpc.public.zkevm-test.net'],
    logoUrl: '',
    nativeCurrency: {
      name: 'Polygon zkEVM Testnet',
      symbol: 'MATIC',
      decimals: 18
    }
  },
  [SupportedChainId.BSC]: {
    networkType: NetworkType.L1,
    explorer: 'https://bscsan.com',
    label: 'BSC',
    rpcUrls: ['https://bsc-dataseed1.binance.org'],
    logoUrl: '',
    nativeCurrency: {
      name: 'Polygon Matic',
      symbol: 'BNB',
      decimals: 18
    }
  },
  [SupportedChainId.BSC_TEST]: {
    networkType: NetworkType.L2,
    explorer: 'https://testnet.bscscan.com',
    label: 'BSC Test',
    logoUrl: '',
    rpcUrls:['https://data-seed-prebsc-2-s2.binance.org:8545'],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18
    }
  },
  [SupportedChainId.BSC]: {
    networkType: NetworkType.L1,
    explorer: 'https://bscsan.com',
    label: 'BSC',
    rpcUrls: ['https://bsc-dataseed1.binance.org'],
    logoUrl: '',
    nativeCurrency: {
      name: 'Polygon Matic',
      symbol: 'BNB',
      decimals: 18
    }
  },
  [SupportedChainId.ARB]: {
    networkType: NetworkType.L2,
    explorer: 'https://arbiscan.io/',
    label: 'Arbitrum',
    logoUrl: '',
    rpcUrls:['https://rpc.ankr.com/arbitrum'],
    nativeCurrency: {
      name: 'ARB',
      symbol: 'ARB',
      decimals: 18
    }
  },
  [SupportedChainId.MEMO]: {
    networkType: NetworkType.L1,
    explorer: 'https://scan.metamemo.one:8080/',
    label: 'Memo Megrez',
    logoUrl: '',
    rpcUrls:['https://chain.metamemo.one:8501'],
    nativeCurrency: {
      name: 'MEMO',
      symbol: 'MEMO',
      decimals: 18
    }
  },

  [SupportedChainId.MEMO_TEST]: {
    networkType: NetworkType.L1,
    explorer: 'https://testscan.metamemo.one:8080/',
    label: 'TEST MEMO',
    logoUrl: '',
    rpcUrls:['https://testchain.abbas.network:24180/'],
    nativeCurrency: {
      name: 'ABBAS MEMO',
      symbol: 'MEMO',
      decimals: 18
    }
  }
};