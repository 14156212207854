import React, { useState, useEffect } from 'react';
import { Button, Modal, Avatar, Dropdown, Progress, Menu, Popover, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ConnectionRejectedError } from 'memouse-wallet';
// import { CopyToClipboard } from 'react-copy-to-clipboard';
import type { MenuProps } from 'antd';
import { CheckCircleTwoTone, WalletOutlined, ProfileOutlined, ApiTwoTone } from '@ant-design/icons';
import useMyWallet from '@/hooks/useMyWallet';
import { useErc20 } from '@/hooks/useContract';
import { ERC20Addr } from '@/constants/address';
// import { toSpecialAmount ,sizeTostr} from '@/utils/amount';
import { sizeTostr } from '@/utils/amount';
// import LanguageSwitching from '../LanguageSwitching';
import { useTranslation } from 'react-i18next';
import mask from '@/assets/images/mask.png';
import sculpture from '@/assets/images/sculpture.png';
// import ModalBackground from '@/assets/images/ModalBackground.png';
// import ModalLens from '@/assets/images/ModalLens.png';
import useWeb3 from '@/hooks/useWeb3';
// import earth from '@/assets/images/earth.png';
// import haFill from '@/assets/images/haFill.png';
import './index.scss';

import { useApi, useStorage, useToken } from '@/hooks/useAppState';
import { shortenAddress } from '@/utils/index';

// import { CHAIN_INFO } from '@/constants/chainInfo';
import useNetworkProvider from '@/hooks/useNetworkProvider';
// import { CHAIN_IDS_TO_NAMES } from '@/constants/chains'; // ,DefaultChainId ,setSelectChainId
import { useProfile } from '@/hooks/useContract';
// import { useParams } from 'react-router-dom';
const Web3Status: React.FC = () => {
  // const { param } = useParams();

  const { selectChainId, haveswitch, setHaveswitch, visibility, setVisibility } = useNetworkProvider();

  const { getProfile } = useProfile(); //profile update,
  //调取了memo的余额
  // const [memo, setMemo] = useState(0);
  const Erc20 = useErc20(ERC20Addr);
  const wallet = useMyWallet();
  const web3 = useWeb3();
  const { t } = useTranslation();

  /// lens //////////////////////////////
  const API = useApi();
  const { setApptype, memoToken, dataList, setMemoToken, profile, setProfile, IsUpdateProfile, profiles, setDisSize, setPreSize, preSize, disSize, setTotalStorage } = useToken(); // lensToken,
  const [api, contextHolder] = notification.useNotification();
  const { storage, setStorage } = useStorage();
  const [items,setItems] = useState([]);
  //  const [isModalOpen, setIsModalOpen] = useState(false);
  //  const [isModalOpen1, setIsModalOpen1] = useState(false);
  //  const [isModalOpen2, setIsModalOpen2] = useState(false);
  //  const [isModalOpen3,setIsModalOpen3] = useState(false);
  const [isModalOpen4, setIsModalOpen4] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [source, setSource] = useState('');
  const [recommender, setRecommender] = useState('');

  const navigate = useNavigate();
  //  const [lensdriveLoading,setLensdriveLoading] = useState(false);
  // const [visibility,setVisibility] = useState(true);
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  });

  //  const [modalText, setModalText] = useState(`${t('web.out')}`);
  // 切换网络
  /*  const changNetWork = useCallback((event) => {
    const tmpChainId = 1 * event;
    const chain = CHAIN_INFO[tmpChainId];
    switchChain({
      chainId: '0x' + tmpChainId.toString(16),
      chainName: CHAIN_IDS_TO_NAMES[tmpChainId],
      nativeCurrency: chain.nativeCurrency,
      rpcUrls: chain.rpcUrls,
      explorerUrl: chain.explorer
    });
  }, []); */
  //获取网址是否sharing
  const url = window.location.href;
  const segments = url.split('?')[0].split('/');
  const sharing = segments[segments.length - 1];
  //导航守卫
  useEffect(() => {
    setProfile({});
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const myParam = urlParams.get('utm_source');
    const tmpsource = urlParams.get('source');
    const tmprecommender = urlParams.get('recommender');

    if (myParam) {
      setApptype(myParam);
    }
    if (wallet.account == null && sharing == '') {
      if (tmpsource || tmprecommender) {
        navigate(`/?source=${tmpsource}&recommender=${tmprecommender}`);
      } else {
        navigate('/');
      }
    }
  }, [wallet.account]);
  //查询页面是否在可观测的角度
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    // 获取 URL 查询参数
    const queryParams = new URLSearchParams(window.location.search);
    const tmpsource = queryParams.get('source');
    setSource(tmpsource);
    const tmprecommender = queryParams.get('recommender');
    setRecommender(tmprecommender);

  }, []);
  useEffect(() => {
    if (!visibility) {
      return;
    }

    if (wallet.chainId == selectChainId && (!haveswitch)) {
      return;
    }
    setHaveswitch(false);
    if (wallet.status === 'connected') {
      setTimeout(() => {
        Login();
      }, 500);
    }
  }, [wallet.chainId, visibility]);


  useEffect(() => {

    // if (apptype=='tokenpocket') {
    //   if (wallet.chainId==selectChainId) {
    //     setTimeout(() => {
    //       Login();
    //     }, 500);
    //     return;
    //   }
    // }
    // setMemoToken('');
    if (wallet.status === 'connected') {
      setTimeout(() => {
        Login();
      }, 500);
    }
  }, [wallet.status, wallet.account]);

  // 计算存储空间  {"Used":20077,"Buysize":0,"Free":1073741824,"Files":17}
  useEffect(() => {

    if (storage.Used) {
      let size = Number(storage.Free) + Number(storage.Buysize) || 0;
      const disSize = sizeTostr(Number(storage.Used) || 0) + '/' + sizeTostr(size);
      if (size > 0) {
        setPreSize(Number(storage.Used) / (size));
      }
      else {
        setPreSize(0);
      }
      setDisSize(disSize);
      setTotalStorage(sizeTostr(size));
    } else {
      let size = Number(storage.Free) + Number(storage.Buysize) || 0;
      setPreSize(0);
      // setDisSize('0MB');
      setDisSize('0B' + '/' + sizeTostr(size));
    }
  }, [storage, memoToken, dataList]);



  useEffect(() => {
    if (memoToken && wallet.status === 'connected') {
      getProfile(wallet.account, wallet.chainId).then(resdata => {
        if (resdata && resdata.status == 200) {
          resdata.data.register = true;
          getimgeData(resdata.data);
        } else {
          setProfile({ register: false });
        }
      })
        .catch(() => {
          setProfile({ register: false }); 
        });
    } else {
      setProfile({});
    }
  }, [IsUpdateProfile, memoToken, wallet.status, wallet.account]);
  /*
   const getimgeData2 = (data)=>{ //index
    if (data.avatar) {
        API({
          method: 'GET',
          responseType: 'arraybuffer',
          url:`${process.env.REACT_APP_URL}mefs/${data.avatar}`
        }).then(function (res) {
          if(res.status == 200){
            const blob = new Blob([res.data], { type: res.headers['content-type'] });
          const imageUrl = URL.createObjectURL(blob);
          data.avatardata=imageUrl;
          }else{
          }
          setProfile(data);
        })
        .catch(function (error) {
          setProfile(data);
        });
     } else {
      setProfile(data);
     }
    };
    */
  //   https://api.mefs.io:10000/mefs/public/bafkreif2arakgy7fhgrrhwyxuo2z4dko6iag2avvtdhxegf65xhidvgx2i?chainid=985
  const getimgeData = (data) => { //index
    if (data.avatar) {
      data.avatardata = `${process.env.REACT_APP_URL}mefs/public/${data.avatar}?chainid=${wallet.chainId}`;
      setProfile(data);
    } else {
      setProfile(data);
    }
  };
  //获取数据接口
  const getStorage = () => {
    try {
      API.get('mefs/storageinfo')
        .then(function (res) {
          if (res && res.status == 200) {
            setStorage(res.data);
          } else {
            setStorage({});
          }
        }).catch(() => {

          setStorage({});
        });
    } catch (error) {

      setStorage({});
    }
  };





  const Login = async () => {
    API({
      method: 'GET',
      url: '/challenge?address=' + wallet.account + '&chainid=' + wallet.chainId,
      data: {
      }
    }).then(async (msg) => {
      const sign = await web3.eth.personal.sign(msg.data, wallet.account, null);
      // const sign = await wallet.ethereum.request({
      //   method: 'personal_sign',
      //   params: [msg.data, wallet.account, '']
      // });

      API({
        method: 'POST',
        url: '/login',
        data: {
          message: msg.data,
          signature: sign,
          recommender,
          source
        }
      }).then((res) => {
        setMemoToken(res.data['access token'] || res.data['accessToken']);
        // setLensdriveLoading(false);
        // setIsModalOpen2(false);
      });
    });
  };


  const handleBalance = async () => {
    if (!Erc20) return;
    const rewardAmount = await Erc20.methods.balanceOf(wallet.account).call();
    if (rewardAmount) {
      // setMemo(toSpecialAmount(rewardAmount));
    }
  };

  useEffect(() => {
    if (wallet.isConnected) {
      handleBalance();
    }
  }, [wallet.chainId, wallet.account, wallet.isConnected]);

  const handle = () => {
    // setModalText(`${t('web.Signing')}`);
    setConfirmLoading(true);
    setTimeout(() => {
      // setIsModalOpen(false);
      setConfirmLoading(false);
      wallet.reset();
    }, 2000);
  };
  const showRecharge = () => {
    navigate('/Introduce');
  };
  const showModal = () => {
    // setIsModalOpen(true);
    // setModalText(`${t('web.out')}`);
    setMemoToken('');
    wallet.reset();
    navigate('/');
  };

  // const showMod = () =>{
  //   setIsModalOpen1(false);
  //   // setIsModalOpen2(true);
  // };


  //调签名
  const showMo = () => {
    activate('injected');
  };
  const goPersonal = () => {
    // navigate('/Personal');
    switch (1 * selectChainId) {
      case 985:
        navigate('/Personal');
        break;
      case 137:
        navigate('/Personal');
        break;
      case 82:
        navigate('/Personal');
        break;
    }
  };
  const goRoleList = () => {
    navigate('/RoleList');
  };
  // const urlString = (profile?.picture?.original?.url) || '';
  // const url = urlString.replace(/:/g, '');
  // const newitems=()=>{
  //   return items.slice(3,4);
  // };
  const filteredItems:MenuProps['items'] = [
    {
      label: <div className="Avatar" >
        <Popover content={wallet.account} trigger="hover">
          <Avatar size={36}
            src={profile['avatardata'] ? profile['avatardata'] : sculpture}
          />
          {shortenAddress(wallet.account)}
        </Popover>
      </div>,
      key: 'Avatar'
    },
    {
      type: 'divider',
      key: 'boos'
    },
    {
      label: <div><Progress
        strokeLinecap="butt"
        percent={preSize * 100}
        showInfo={false}
        format={() => disSize}
        style={{ height: '12px' }}
      />
        <div className="LeftList_Progress">
          {/* <span>{t('Space')}</span> */}
          <span>{disSize}</span>
        </div></div>,
      key: 'LeftList_Progress'
    },
    {
      label:
        <div>
          {(selectChainId == 82 || selectChainId == 985) &&
            <div className="items_label1" onClick={() => goPersonal()}><ProfileOutlined />{t('web.profiler')}</div>
          }
        </div>,
      key: 'selectChainId'
    },
    {
      label:
        <div>
          {(selectChainId == 985) &&
            <div className="items_label1"
              onClick={showRecharge}>
              <span className="iconfont icon-chongzhi"></span>Recharge
            </div>
          }</div>,
      key: 'items_label2'
    },
    {
      label: <div className="items_label1"
        onClick={showModal}
      ><span className="iconfont icon-tuichu"></span>{t('web.Logout')}</div>,
      key: 'items_label1'
    },
    {
      type: 'divider',
      key: 'divider'
    },
    {
      label:
        <div className="items_iconfont">
          <div>
          <a href="https://twitter.com/drive3_net" target="_blank" rel="noreferrer"><i className="iconfont icon-twitter"></i></a>
            <a href=" https://t.me/drive3global" target="_blank" rel="noreferrer"><i className="iconfont icon-telegram"></i></a>
            <a href="https://github.com/drive3net" target="_blank" rel="noreferrer"><i className="iconfont icon-github"></i></a>
{/*             <a href="https://twitter.com/ethdrive_net" target="_blank" rel="noreferrer"><i className="iconfont icon-twitter"></i></a>
            <a href="https://t.me/ethdriveglobal" target="_blank" rel="noreferrer"><i className="iconfont icon-telegram"></i></a>
            <a href="https://github.com/ethdriveio/ethdriveui.git" target="_blank" rel="noreferrer"><i className="iconfont icon-github"></i></a> */}
          </div>
          <span className="Current">{t('web.Current')}</span>
        </div>,
      key: '8'
    }
  ];
  useEffect(() =>{
    if(selectChainId == 985){
      setItems(filteredItems);
    }else{
      const remainingItems = filteredItems.slice(0, 3).concat(filteredItems.slice(5));
      setItems(remainingItems);
    }
  },[selectChainId,memoToken]);
  useEffect(() => {
    if (memoToken) {
      getStorage();
    } else {
      setStorage({});
    }
  }, [memoToken]);

  // const guanbi = () => {
  //   setIsModalOpen(false);
  // };
  const handleCancel = () => {
    // setIsModalOpen1(false);
    // setIsModalOpen2(false);
    setIsModalOpen4(false);
  };
  // const handleOk = () => {
  //   setIsModalOpen1(false);
  // };
  const handleSwitch = (index, isame) => {
    if (isame) return;
    if (index > profiles.length) return;
    setProfile(JSON.parse(JSON.stringify(profiles[index])));
    setIsModalOpen4(false);
  };
  //登录
  const activate = (connector) => {
    setMemoToken('');
    wallet.reset();
    wallet.connect(connector);
  };
  const openNotification = () => {
    api.open({
      message: 'Notification Title',
      description: wallet.error instanceof ConnectionRejectedError ? 'Connection error: the user rejected the action' : wallet.error.name,
      icon: <ApiTwoTone style={{ color: '#108ee9' }} />
    });
    wallet.reset();
  };
  const menu2 = (
    <Menu className="menu2">
      <Menu.Item >
        <p className="menu">Connect Wallet</p>
        <Button onClick={showMo}><img src={mask} />MetaMask</Button>
      </Menu.Item>

    </Menu>
  );


  return (
    <>
      {contextHolder}
      {(() => {
        if (wallet.error?.name) {
          return (
            <div className="connecting">
              {/* <span className="connecting-walletError">
                {wallet.error instanceof ConnectionRejectedError
                  ? 'Connection error: the user rejected the action' : wallet.error.name
                }
              </span> */}
              <Button
                size="large"
                type="primary"
                onClick={() => openNotification()
                }
              >
                {t('web.Reconnect')}
              </Button>
            </div>
          );
        }

        if (wallet.status === 'connecting') {
          return (
            <div className="connecting">
              <Button
                size="large"
                type="primary"
                onClick={() => wallet.reset()}
              >

                {t('web.Cancel')}
              </Button>
            </div>
          );
        }

        if (wallet.status === 'connected') {
          return (
            <div className="walletAccount">
              <Dropdown menu={{ items }}
                trigger={['click']}
                overlayStyle={{ marginTop: '100px' }}
                className="Mobile_List"
              >
                <Avatar size={36}
                  src={profile['avatardata'] ? profile['avatardata'] : sculpture}
                />
              </Dropdown>
              <Avatar size={36}
                onClick={goRoleList}
                className="MobileIcon"
                src={profile['avatardata'] ? profile['avatardata'] : sculpture}
              />
            </div>
          );
        }

        return (
          <div>

            <Dropdown overlay={menu2} placement="bottomRight" trigger={['click']}>
              <Button
                type="primary"
                size="large"
                className="connect"
              >
                {t('web.Sign')}
              </Button>
            </Dropdown>
            <Dropdown overlay={menu2} placement="bottomRight" trigger={['click']}>
              <WalletOutlined className="WalletColumn" />
            </Dropdown>
          </div>
        );
      })()}
      <Modal
        title={<div className="iconP">{t('web.Change_Profile')}</div>}
        open={isModalOpen4}
        onOk={handle}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
        width={400}
        bodyStyle={{ padding: '10px' }}
        centered
      >
        <>
          {
            profiles.map((item, index) => {
              return (
                <Button className="LeftList_Avatar"
                  key={item.name}
                  onClick={() => handleSwitch(index, item.name == profile.name)}
                  style={{ justifyContent: 'space-between', width: '100%', display: 'flex', height: '45px', alignItems: 'center', marginBottom: '10px', paddingLeft: '10px', borderRadius: '10px' }}
                >
                  <span>
                    <Avatar style={{ marginRight: '7px' }}
                      size={30}
                      src={'https://user-content.lenster.xyz/300x300/https://gateway.ipfscdn.io/' + ((item?.picture?.original?.url) || '').replace(/:/g, '')}
                    />
                    {item.name}</span>
                  {(item.name == profile.name) && <span> <CheckCircleTwoTone twoToneColor="#52c41a" /></span>}
                </Button>
              );
            })
          }
          {/* <p><span><Avatar className="LeftList_Avatar" shape="square" size={30}  src={'https://user-content.lenster.xyz/300x300/https://gateway.ipfscdn.io/'+url} /> {profile.name}</span></p> */}
        </>
      </Modal>
      {/* <Modal
        title={<div className="iconPa"><img src={iconPark}/>{t('web.LogOut')}</div>}
        open={isModalOpen}
        onOk={handle}
        centered
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        okText={t('web.yes')}
        cancelText={t('web.No')}
        bodyStyle={{padding:'40px 24px 40px'}}
      >
        <p>{modalText}</p>
      </Modal> */}
    </>
  );
};

export default Web3Status;