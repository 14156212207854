import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from '@/components/Header';
import Home from '@/page/Home';
import Pay from '@/page/Pay';
import Uploader from '@/page/UploadPage';
import Introduce from '@/page/Introduce';
// import Message from '@/page/Message';
import OrderGoods from '@/page/OrderGoods';
import Personal from '@/page/Personal';
import RoleList from '@/page/RoleList';
import MintNFT from '@/page/NFTList';
import NFTDetail from '@/page/NFTDetail';
import NFTCreate from '@/page/NFTCreate';
import Sharing from '@/page/SharingInterface';
// import Choose from '@/page/choose';
import Error404 from '@/page/Error404';
// import {useToken} from '@/hooks/useAppState';
// import PrivateRoute from './PrivateRoute';



function Routers() {

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        {/* <Route path="/"
          element={<Choose />}
        /> */}
        <Route path="/"
          element={<Home />}
        />
       <Route path="/group/:group"
         element={<Home />}
        />
        <Route path="/Pay"
          element={<Pay />}
        />
        <Route path="/Introduce"
          element={<Introduce />}
        />
        <Route path="/Uploader"
          element={<Uploader />}
        />
        <Route path="/Message"
          element={<Personal />}
        />
        <Route path="/OrderGoods"
          element={<OrderGoods />}
        />
        <Route path="/Personal"
          element={<Personal />}
        />
        <Route path="/RoleList"
          element={<RoleList />}
        />
        <Route path="/MintNFT"
          element={<MintNFT />}
        />
        <Route path="/NFTDetail"
          element={<NFTDetail />}
        />
        <Route path="/NFTCreate"
          element={<NFTCreate />}
        />
        <Route path="/Sharing"
          element={<Sharing/>}
        />
       <Route path="/group/:group/Sharing"
         element={<Sharing/>}
        />
        <Route path="*"
          element={<Error404 />}
        />
      </Routes>
    </BrowserRouter>
  );
}


export default Routers;
