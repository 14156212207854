import React,{useState,useEffect} from 'react';
// import { useParams  } from 'react-router-dom';
import { FileOutlined,FieldTimeOutlined,ExclamationCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';
// import {useToken} from '@/hooks/useAppState';
import {useShare} from '@/hooks/useContract';
// import {useGroupPathRoute} from '@/hooks/useAppState';
import {useGroupPath} from '@/hooks/useAppState';
import {ObtainImageName} from '@/utils/index';
import './index.scss';

function SharingInterface() {
  const { getShare } = useShare();
  const [isLoad, setIsLoad] = useState(false);
  const [shareId, setShareId] = useState('');
  const [hours,setHours] = useState('');
  const [data,setData] = useState({
    filename:'',
    chainid:'',
    shareid:'',
    expire:'',
    mid:'',
    address:''
});
// const grouppathroute = useGroupPathRoute();
const grouppath = useGroupPath();
useEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const share = urlParams.get('shareId');
  setShareId(share);
  setHours(String(new Date()));
  console.log('isLoad',isLoad);
},[shareId]);

  useEffect(() => {
    // if (url != null && shareId !== '') {
      console.log(data);
      getShare(shareId, '', setIsLoad)
        .then((data) => {
          // 处理成功返回的数据
          console.log('data',data);
          setData(data);
        })
        .catch((error) => {
          // 处理错误信息
          console.error(error);
        });
    // }
  }, [shareId]);
  const download = ()=>{
    const link = document.createElement('a');
    // link.href= `${process.env.REACT_APP_URL}${grouppath}mefs/${data.mid}?chainid=${data.chainid}&address=${data.address}`;
    link.href = `${process.env.REACT_APP_URL}${grouppath}share/${shareId}`;
    link.click();
};

  return (
    <div className="SharingInterface">
      <div className="container">
        <div className="Home">
        <div className="Home_Personal">
        {(data.chainid != '') ?
          <div className="SharingTop">
              <div>
                <p>
                <FileOutlined  twoToneColor="#298DF8" className="img"/>
                <span>{data.filename}</span>
                </p>
                <p style={{alignItems:'flex-start'}} className="SharingField">
                  <span><FieldTimeOutlined  className="imgs"/>{hours}</span>
                  {data.expire != '-1'?
                  <p>过期时间:24小时后</p>
                  :
                  <p>过期时间:永久有效</p>
                  }
                </p>
              </div>
              <div>
                  <Button onClick={() => download()}>下载</Button>
              </div>
          </div>
          :<div style={{padding:'60px 20px',display:'flex',flexDirection:'column',alignItems:'center'}}>
            <ExclamationCircleOutlined style={{color:'red',fontSize: '80px'}}/>
            <p style={{fontFamily:'Roboto-regular',fontSize:'24px',marginTop:'20px',color:'rgb(117 101 101)'}}>The file is empty or the sharing link has expired</p>
          </div>
          }
          {ObtainImageName(data.filename) != null &&
          <div className="SharingBotton">
            {/* <img src={`${process.env.REACT_APP_URL}${grouppath}share/${shareId}`}/> */}
            <img src={`${process.env.REACT_APP_URL}${grouppath}share/${shareId}`}/>
          </div>
          }
        </div>
        </div>
      </div>
    </div>
  );
}

export default SharingInterface;
