import React, { useEffect, useState,useMemo } from 'react';
import { useParams,useLocation ,useNavigate} from 'react-router-dom';
import { Button, notification ,Spin , Table, Modal, message, Upload ,Tooltip,Popover ,Image,Dropdown,Radio,Input,Tabs} from 'antd';
import type {MenuProps,RadioChangeEvent } from 'antd';
import {  DownloadOutlined ,EyeOutlined,ShareAltOutlined,DeleteOutlined,CloseCircleFilled,ExclamationCircleFilled} from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd/es/upload/interface';
// import axios from 'axios';
import useMyWallet from '@/hooks/useMyWallet';
// import useWeb3 from '@/hooks/useWeb3';
// import useAppState from '@/hooks/useAppState';
import {useApi,useToken,useGroupPath,useGroupPathRoute} from '@/hooks/useAppState';
import { useShare } from '@/hooks/useContract';
import { formatBytes, utc2beijing } from '@/utils/index';
import { shortenFileName ,shortenFileMid} from '@/utils/index';
import useNetworkProvider from '@/hooks/useNetworkProvider';
import {ObtainImageName} from '@/utils/index';
// import { toSpecialAmount } from '@/utils/amount';
import { useTranslation } from 'react-i18next';

import arcoDesign from '@/assets/images/arcoDesign.png';

// import providerNode from '@/api/provider';
import './index.scss';
import UploadPage from '../UploadPage';
const { confirm } = Modal;

// eslint-disable-next-line react/no-multi-comp
const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const { group } = useParams();
  const location = useLocation();
  const grouppath = useGroupPath();
  const grouppathroute = useGroupPathRoute();
  const navigate = useNavigate();
 
  const { createShare } = useShare(); // getShareList, getShare, createShare, deleteShare, saveShare

  const [visible, setVisible] = useState(false);
  const {memoToken ,receive,dataList, setDataList,setSharingplugins,applicationID,setTouDi,setGroupId}= useToken(); //profile
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [selectMid, setSelectMid] = useState('');
  const [Public,setPubilc]= useState(false);
  const {selectChainId} = useNetworkProvider();
  const [move, setMove] = useState({ID:'',Mid:false,Name:'',Shared:''});
  // const navigate = useNavigate();
  // const [Srcimg, setSrcimg] = useState([]);
  
  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath!='/') {
      // console.log('currentPath',currentPath);
      setGroupId(group);
    }

  }, [group]);

  const API = useApi();
  // const tableData: {
  //   current: 1,
  //   pageSize: 10,
  //   total:0
  // }
  // 移动端点击分享事件
  const items: MenuProps['items'] = [
    {
      label: <a onClick={() =>phoneload()}>{t('Home.Download')}</a>,
      key: '0'
    },
    {
      label: Public?<a onClick={() =>phoneCopy()}>{t('Share_File.Copy')}</a>
      :
      <a onClick={() =>Moveload()}>{t('Share_File.Share')}</a>,
      key: '1'
    },

    !Public &&
      {
        label: <a onClick={() =>moveDeleteConfirm()}>Delete</a>,
        key: '2'
      },
      (!Public && move.Shared ) &&
      {
        label: <a onClick={() => moveCancelConfirm()}>Cancel sharing</a>,
        key: '3'
      }
      // {
      //   label: <a onClick={() => phoneNft()}>{t('Share_File.NFT')}</a>,
      //   key: '4'
      // }
  ];

  const columns: Array<any> = [
    {
      title: `${t('Home.Document')}`,
      render: (record) => <div className="ObtainImage">
        <Popover content={decodeURIComponent(record.Name)}  trigger="hover">{shortenFileName(decodeURIComponent(record.Name))}
        </Popover>
        {/* {ObtainImageName(record.Name) != null &&
        <div>
           <EyeOutlined onClick={() => Viewpicture(record)} className="ObtainImageName"/>
        </div>
        } */}
        {ObtainImageName(record.Name) == '' &&
        <div>
        <EyeOutlined onClick={() => Viewpicture(record)} className="ObtainImageName"/>
        </div>
        }
      </div>,
      fixed: 'left'
    },
    {
      title: `${t('Home.File_Upload')}`,
      dataIndex: 'ModTime',
      render: ModTime => `${utc2beijing(ModTime)}`,
      fixed: 'left',
      sorter:(a , b) =>{
        const aTime = new Date(a.ModTime).getTime();
        const bTime = new Date(b.ModTime).getTime();
        return aTime - bTime;
      },
      defaultSortOrder: 'descend'
    },
    {
      title: `${t('Home.CID')}`,
      dataIndex: 'Mid',
      render: Mid => <div className="ObtainImage"><Popover content={Mid}  trigger="hover">{shortenFileMid(`${Mid}`)}</Popover></div>,
      fixed: 'left',
      textWrap: 'word-break',
      ellipsis: true
    },
    {
      title: `${t('Home.Size_of_File')}`,
      dataIndex: 'Size',
      render: Size => `${formatBytes(Size, 2)}`,
      fixed: 'left',
      width: 120
    },
    {
      title: `${t('Home.File_Download')}`,
      Name: 'operation',
      fixed: 'left',
      width: 190,
      render: (record) =>( //,index
      <p style={{display:'flex',marginBottom:'0px'}}>
        <Button
          type="dashed"
          icon={<DownloadOutlined />}
          title="Download"
          // loading={loadings[index.Size]}
          // onClick={() => enterLoading(2)}  download(record,index.Size)}
          onClick={() => download(record)}
        />
        {Public?
        <span style={{display:'flex'}}>
        <Button
          className="Replicating"
          type="dashed"
          title="Copy"
          icon={<i className="iconfont icon-fuzhi"/>}
          // loading={loadings[index.Size]}<CopyOutlined />
          // onClick={() => enterLoading(2)}  download(record,index.Size)}
          onClick={() =>publicCopy(record)}
        />
        <div>
        {(selectChainId == 985) &&
        <Button
          className="Replicating"
          type="dashed"
          title="Create NFT"
          icon={<i className="iconfont icon-leimutubiaozhubaoshoulian"/>}
        // loading={loadings[index.Size]}<CopyOutlined />
        // onClick={() => enterLoading(2)}  download(record,index.Size)}
          onClick={() =>goNFTCreate(record)}
      />
      }
        </div>

      </span>
        :
        <span style={{display:'flex'}}>
        <Button
          className="Replicating"
          type="dashed"
          title="Share"
          icon={<ShareAltOutlined />}
          onClick={() => Shareload(record)}
        />
        <Button
          className="Replicating"
          type="dashed"
          title="Delete"
          icon={<DeleteOutlined />}
          onClick={() => showDeleteConfirm(record)}
        />
        {record.Shared && <Button
          className="Replicating"
          type="dashed"
          title="Cancel sharing"
          icon={<i className="iconfont icon-quxiao"/>}
          onClick={() => showCancelConfirm(record)}
        />}
        </span>
      }
      </p>
      )
    }
  ];
  // const navigate = useNavigate();
  // const web3 = useWeb3();
  //通知提示框
  const [api, contextHolder] = notification.useNotification();
  const {account,chainId, status } = useMyWallet();
  const [isUpload, isSetUpload] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);
  const [address, SetAddress] =useState('');
  const [ImgList, setImgList] = useState('');
  const [fileSize, setFileSize] = useState(0);
  const [messageApi, contextHold] = message.useMessage();
  const [open, setOpen] = useState(false);
  const [sharing, setSharing] = useState(false);
  const [valueRadio , setValueRadio] = useState(1);
  const [valueInput, setValueInput] = useState('');

  //点击删除文件代码
  const showDeleteConfirm = (row) => {
    confirm({
      title: 'Are you sure you want to delete it?',
      icon: <CloseCircleFilled style={{color:'red'}} />,
      // content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        DeleteItem(row.ID);
        // console.log('OK');
      },
      onCancel() {
        console.log(loading);
        // console.log('Cancel');
      }
    });
  };

    //点击取消分享
    const showCancelConfirm = (row) => {
      confirm({
        title: 'Are you sure you want to cancel share it?',
        icon: <ExclamationCircleFilled style={{color:'#FFBA00'}} />,
        // content: 'Some descriptions',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          ShareDeleteItem(row.Mid,row.Name);
        },
        onCancel() {
          // console.log('Cancel');
        }
      });
    };

  const hideModal = () => {
    setOpen(false);
  };

  function handleTabChange(key) {
    setLoad(true);
    if(key == 1){
      setPubilc(false);
    }else{
      setPubilc(true);
    }
  }

  useEffect(() => {
    if (memoToken && status === 'connected') {
      getDataList();
      isSetUpload(false);
    } else {
      setDataList([]);
      isSetUpload(true);
      setLoad(false);
    }
  }, [memoToken,status,account,Public]);

  // 获取文件列表
  const getDataList = ()=>{
    API.get('mefs/listobjects')
    .then(function (res){
      if (res && res.status == 200) {
          let arr =res.data.Objects;
          if(!arr){
            setDataList([]);
            setLoad(false);
            return;
          }
            arr = arr.sort(function(a,b){
              return ((b.ModTime)>(a.ModTime))?10:-10;
            });
            setLoad(false);
            setDataList(arr.filter(item => item.Public == Public));
 
            // setDataList(res.data.Object);
            // console.log('cid:',arr[0].Cid);
            // console.log('cid:',arr);
        } else {
          setLoad(false);
          setDataList([]);
        }
    });
  };
    // 删除文件
    const DeleteItem= (id)=>{
      const index=dataList.findIndex((item)=>item.ID==id);
      if (index >= 0 && (dataList[index].Public || dataList[index].Shared)) {
        messageApi.open({
          type: 'error',
          content: 'Public files and shared files cannot be deleted!',
          duration: 5
        });
        // console.log('Public files and shared files cannot be deleted!');
        return;
      }
      API.get('mefs/delete?id='+id)
      .then(function (res){
        if (res && res.status == 200) {
          // console.log('index',index);
          if (index>=0) {
            dataList.splice(index, 1);
            setDataList([...dataList]);
            messageApi.open({
              type: 'success',
              content: 'File deleted successfully!',
              duration: 5
            });
            // console.log('File deleted successfully!');
          }
          } else {
            messageApi.open({
              type: 'error',
              content: 'Delete file error!',
              duration: 5
            });
          //  console.log(res);
          }
      });
    };
    // 删除分享文件
    const ShareDeleteItem= (mid,name)=>{
          API.delete('share',{data:{mid,type:0,name}})
          .then(function (res){
            if (res && res.status == 200) {
              const index=dataList.findIndex((item)=>item.Mid==mid && item.Name==name);
              if (index>=0) {
                dataList[index].Shared=false;
                setDataList([...dataList]);
                messageApi.open({
                  type: 'success',
                  content: 'Cancel sharing success!',
                  duration: 5
                });
                // console.log('Cancel sharing success!');
              }
              } else {
                messageApi.open({
                  type: 'error',
                  content: 'Cancel sharing file error!',
                  duration: 5
                });
              //  console.log(res);
              }
        });
    };
  //上传
  const handleUpload = () => {
    let fileexit =null;
    for (let index = 0; index < fileList.length; index++)
    {
      // 检查文件是否存在于 dataList 中
      fileexit= dataList.find(item => item.Name == fileList[index].name);
      if (fileexit) {
        // 如果文件存在，则打开一个 API，显示文件过大的错误消息
        api.open({
          message: `${t('Home.File_too_large')}`,
          type: 'error',
          duration: 0
        });
        break;
      }else if(fileList[0].size >= 500 * 1024 *1024){
        // 如果文件大小超过 500MB，则打开一个 API，显示上传错误的消息
        api.open({
          message: 'Upload Error',
          type: 'error',
          description:`${t('Home.File_size')}`,
          duration: 0
        });
        fileexit=true;
        break;
      }
    }
    if (!fileexit) {
       // 如果文件不存在，则执行上传操作
      const reader = new FileReader();
      fileList.forEach(file => {
      reader.readAsArrayBuffer(file.originFileObj);
      reader.onload = async() => {
        let formData = new FormData();
        formData.append('file', file.originFileObj);
        formData.append('public',Public?'true':'false');
        await API({
          method: 'POST',
          url:'/mefs/',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data:formData
        }).then(function (res) {
          if(res.status == 200){
            setUploading(false);
            setFileList([]);
            getDataList();
            // messageApi.open({
            //   type: 'success',
            //   content: `${t('Home.upload_successfully')}`,
            //   duration: 0
            // });
            api.open({
              message: `${t('Home.upload_successfully')}`,
              type: 'success',
              duration: 0
            });
            setIsModalOpen(false);
          }else{
            // messageApi.open({
            //   type: 'error',
            //   content: `${t('Home.upload_failed')}`,
            //   duration: 5
            // });
            api.open({
              message: 'Upload Error',
              type: 'error',
              description:`${t('Home.upload_failed')}`,
              duration: 0
            });
            setUploading(false);
          }
        })
        .catch(function () {
          api.open({
            message: 'Upload Error',
            type: 'error',
            description:`${t('Home.file_err')}`,
            duration: 0
          });
          setUploading(false);
        });
      };
    });
    setUploading(true);
  }
  };

  // 下载方法account
  const download = (items)=>{
      const link = document.createElement('a');
      if(Public){
        link.href = `${process.env.REACT_APP_URL}${grouppath}mefs/public/${items.Mid}?chainid=${chainId}&address=${account}`;
      }else{
        link.href = `${process.env.REACT_APP_URL}${grouppath}mefs/${items.Mid}?token=${memoToken}`;
      }
      // console.log(link.href);
      link.click();
  };
   /*
  //点击下载
  const download2 = (items)=>{ //index
     API({
      method: 'GET',
      responseType: 'arraybuffer',
      url:`${process.env.REACT_APP_URL}mefs/${items.Mid}`
    }).then(function (res) {
      console.log('点击下载',res);
      if(res.status == 200){
      console.log('download');
        const blob = new Blob([res.data], { type: res.headers['content-type'] });
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = window.URL.createObjectURL(blob);
        link.download = items.Name;
        link.click();
        window.URL.revokeObjectURL(link.href);
      }else{
        messageApi.open({
          type: 'error',
          content: `${t('Home.upload_download')}`,
          duration: 5
        });
      }
    })
    .catch(function (error) {
      console.log(error);
      messageApi.open({
        type: 'error',
        content: `${t('Home.upload_download')}`,
        duration: 5
      });
    });
  };
    */
  //移动端存储items值
  const toggleVisible=(items)=>{
    setTouDi(items);
    SetAddress(`${items.Mid}`);
    // console.log(moveNft);
    setMove(items);
    // console.log(move);
  };
  //移动端删除分享方法
  const moveCancelConfirm = () => {
    confirm({
      title: (
        <div style={{padding: '30px 0px 10px'}}>Are you sure you want to cancel share it?</div>
      ),
      icon: <ExclamationCircleFilled style={{color:'#FFBA00',padding: '30px 0px 10px'}} />,
      // content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        ShareDeleteItem(move.Mid,move.Name);
        // console.log('OK');
      },
      onCancel() {
        // console.log('Cancel');
      }
    });
  };
    //点击删除文件代码
    const moveDeleteConfirm = () => {
      confirm({
        title: (
          <div style={{padding: '30px 0px 10px'}}>Are you sure you want to delete it?</div>
        ),
        icon: <CloseCircleFilled style={{color:'red',padding: '30px 0px 10px'}} />,
        // content: 'Some descriptions',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
          DeleteItem(move.ID);
        },
        onCancel() {
          // console.log('Cancel');
        }
      });
    };
    //分享数据
  const Shareload = (items) =>{
    setSelectMid(items.Mid);
    // console.log('列表相关数据',items);
    setSharingplugins(items);
    SetAddress(`${process.env.REACT_APP_URL}${grouppath}mefs/${items.Mid}`);
    setSharing(true);
  };
  const handleInput = (e) => {
    const newValue = e.target.value;
    if (/^[0-9]*$/.test(newValue)) {
      setValueInput(newValue);
    }
  };
  //分享点击单选按钮
  const onChange = (e: RadioChangeEvent) => {
    setValueRadio(e.target.value);
  };
  //分享组件
    const Moveload = () =>{
      setSelectMid(address);
      setSharing(true);
    };
    const MoveLoads = () =>{
      // console.log('loading',loading);
      const data ={mid:selectMid,type:0,expire:0};
      if (valueRadio==2) {
        if (valueInput) {
          data.expire =0;
        }
      }
      createShare(data,setLoading).then(data=>{
        // const  currentUrl= window.location.href;
        const  currentUrl= window.location.origin;
        const shareId = data.match(/[^/]+$/)[0];
        const finalUrl = `${currentUrl}/${grouppathroute}Sharing?shareId=${shareId}`;
        SetAddress(finalUrl); //${process.env.REACT_APP_URL}mefs/
        setOpen(true);
        setSharing(false);
        const index=dataList.findIndex((item)=>item.Mid==selectMid );
              if (index>=0) {
                dataList[index].Shared=true;
                setDataList([...dataList]);
              }
      });

    };
    
 //移动端的点击跳转NFT
  // const phoneNft =() =>{
  //   navigate('NFTCreate');
  // };
  //移动端的下载
  const phoneload = () =>{
    const link = document.createElement('a');
    if(Public){
      link.href = `${process.env.REACT_APP_URL}${grouppath}mefs/public/${address}?chainid=${selectChainId}&address=${account}`;
    }else{
      link.href = `${process.env.REACT_APP_URL}${grouppath}mefs/${address}?token=${memoToken}`;
    }
    link.click();
  };
  //移动端的复制粘粘
  const phoneCopy = () =>{
    const oInput = document.createElement('input');
    oInput.value = `${process.env.REACT_APP_URL}${grouppath}mefs/public/${address}?chainid=${selectChainId}`;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand('Copy'); // 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display = 'none';
    document.body.removeChild(oInput);
    api.open({
      message: 'Link copied successfully',
      type: 'info',
      duration: 0
    });
  };
  //公开文件的复制粘粘
  const publicCopy = (items) =>{
    const oInput = document.createElement('input');
    oInput.value = `${process.env.REACT_APP_URL}${grouppath}mefs/public/${items.Mid}?chainid=${selectChainId}`;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand('Copy'); // 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display = 'none';
    document.body.removeChild(oInput);
    
    api.open({
      message: 'Link copied successfully',
      type: 'info',
      duration: 0
    });
  };
  //创建NFT
  const goNFTCreate =(items) =>{
    setTouDi(items);
    navigate('NFTCreate');
  };
  //复制粘粘
  const handleCopy = () =>{
    const oInput = document.createElement('input');
    oInput.value = address;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand('Copy'); // 执行浏览器复制命令
    oInput.className = 'oInput';
    oInput.style.display = 'none';
    document.body.removeChild(oInput);
    api.open({
      message: 'Link copied successfully',
      type: 'info',
      duration: 0
    });
  };
    //上传组件取消弹窗
    const closeSharing = () =>{
      setOpen(false);
      setSharing(false);
    };
    const SharingEvent = () =>{
      setOpen(false);
      handleCopy();
    };
  const Viewpicture = (items) =>{
    setImgList(items.Mid);
    setVisible(true);
  };
  const handleChange: UploadProps['onChange'] = (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-2);
    setFileList(newFileList);
    setFileSize(newFileList[0] ? newFileList[0].size : 0);
  };

  const props: UploadProps = {
    onChange: handleChange,
    onRemove: file => {
      setUploading(false);
      setFileSize(0);
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([...fileList, file]);
      return false;
    },
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068'
      },
      strokeWidth: 3,
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setUploading(false);
    setFileList([]);
    setFileSize(0);

  };

  // 取消弹窗
  const handleCancel = () => {
    setIsModalOpen(false);
    setUploading(false);
    setFileList([]);
    setFileSize(0);
  };
  const locreceive = useMemo(() => {
    if (window && window.localStorage) {
      if (!window.localStorage.getItem('Receive'+account)) {
        return false;
      }
      try {
        return JSON.parse(window.localStorage.getItem('Receive'+account));
      } catch (err) {
         return false;
      }
    } else {
      return false;
    }
  },[receive,account]);
  return (
    <>
    {contextHolder}
    {contextHold}
        <div className="HomePage">
        {status == 'connected' && memoToken  &&
  <>
        <div className="container">
          <div className="Home">
            <h2 style={{fontSize:'26px',color:'#35445e'}}>{t('Share_File.My_Files')}</h2>
              <Tabs onChange={handleTabChange}
                className="handleTabChange"
                defaultActiveKey={Public?'2':'1'}
                items={[
                  {
                    label: t('Share_File.Private_Files'),
                    key: '1'
                  },
                  {
                    label: t('Share_File.Public_Documents'),
                    key: '2'
                  }
                ]}
              />
            <div className="Home_button">
                {Public ?
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    disabled={(isUpload && !locreceive) || ![137,1,985,82,56,42161].includes(chainId)}
                    onClick={showModal}
                    style={{ background: (isUpload && !locreceive) || ![137,1,985,82,56,42161].includes(chainId)
                      ? '#ccc':'#67C23A', color: '#fff' }}
                  >
                    {t('Upload')}
                  </Button>
                  :
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    disabled={(isUpload && !locreceive) || ![137,1,985,82,56,42161].includes(chainId)}
                    style={{ background: (isUpload && !locreceive) || ![137,1,985,82,56,42161].includes(chainId)
                      ? '#ccc':'', color: '#fff' }}
                    onClick={showModal}
                  >
                    {t('Upload')}
                  </Button>
                }
            </div>
            <Spin tip="Loading..." spinning={load} className="PcLoading">
            <Table
              columns={columns}
              dataSource={dataList}
              locale={{ emptyText: (
              <div className="emptyText">
              <img src={arcoDesign}/>{t('Home.No_Data')}
              </div>
              )
            }}
              rowKey={(record) => shortenFileName(record.Mid+Math.random())}
              pagination={{pageSize:10,showSizeChanger:false}}
            />
              <Image
                style={{ display: 'none' }}
                className="ImageShow"
                src={'https://lensmiddlewareapi.metamemo.one:8080/mefs/'+ImgList}
                preview={{
                  visible,
                  onVisibleChange: (value) => {
                    setVisible(value);
                  },
                  src: 'https://lensmiddlewareapi.metamemo.one:8080/mefs/'+ImgList
                }}
              />
            </Spin>
          </div>
        </div>
        {/* 分享组件，分享多少天 */}
          <Modal title={<div className="iconPa">{t('Share_File.Share_File')}</div>}
            open={sharing}
            onOk={hideModal}
            closable={false}
            style={{ top: 260}}
            onCancel={hideModal}
            footer={[
              <Button key="back"
                onClick={closeSharing}
                // disabled={uploading == true}
                style={{
                  width: '50%',
                  height: '36px'
                }}
              >
                {t('Home.Close')}
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={MoveLoads}
                className="handleUpd"

                loading={uploading}
                style={{
                  width: '50%'
                  , background: '#1883FB'
                  , border: '0px',
                  height: '36px'
                  ,
                  marginLeft:'24px'
                }}
              >
                {sharing ?`${t('Share_File.Create_Share')}`:'Copy LInk'}
                {/* {uploading ? 'Uploading' : `${t('Home.Start_upload')}`} */}
              </Button>
            ]}
            okText="确认">
            <div className="Uploaded">
              <span >{t('Share_File.Please')}</span>
              <p className="RadioCss">
                <Radio.Group onChange={onChange} value={valueRadio} className="UploadPlease">
                  <span>{t('Share_File.period')}</span>
                  <Radio value={1}>{t('Share_File.Permanently')}</Radio>
                  <Radio value={2}><Input value={valueInput} pattern="[0-9]*" maxLength={4} onChange={handleInput} style={{marginRight:'5px',borderRadius:'5px',width:'60px'}}/>{t('Share_File.day')}</Radio>
                </Radio.Group></p>
            </div>
          </Modal>
        {/* 分享组件 */}
      <Modal title={
            <div className="iconPa">{
              Public?'true' &&
                t('Share_File.Share_File'):
                'false' &&
              t('Share_File.Copy_file')
      }</div>
    }
        open={open}
        onOk={hideModal}
        style={{ top: 260 }}
        onCancel={hideModal}
        footer={[
          <Button key="back"
            onClick={closeSharing}
            // disabled={uploading == true}
            style={{ width: '50%',
            height:'36px'
              }}
            >
            {t('Home.Close')}
          </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={SharingEvent}
              className="handleUpd"
              loading={uploading}
              style={{ width: '50%'
                , background: '#1883FB'
                , border: '0px',
                height:'36px',
                marginLeft:'24px'
              }}
            >
              {t('Share_File.Copy_LInk')}
              {/* {uploading ? 'Uploading' : `${t('Home.Start_upload')}`} */}
            </Button>
        ]}
        okText="确认">
        <div className="UploadedLink">
          <span className="Address" onClick={handleCopy}>{address}</span>
        </div>
      </Modal>
      {/* 上传组件 */}
        <Modal title={<div className="iconPa">{t('Home.Upload_Files')}</div>}
          open={isModalOpen}
          onOk={handleOk}
          closable={false}
          style={{ top: 260 }}
          className="antModalFooter"
          footer={[
            <Button key="back"
              onClick={handleCancel}
              disabled={uploading == true}
              style={{ width: '50%',
              height:'36px'
              }}
            >
            {t('Home.Close')}
          </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleUpload}
              disabled={fileList.length === 0}
              className="handleUpd"
              loading={uploading}
              style={{ width: '50%'
                , background: '#1883FB'
                , border: '0px',
                height:'36px'
              }}
            >
              {uploading ? 'Uploading' : `${t('Home.Start_upload')}`}
            </Button>
          ]}
        >
          <Upload {...props}
            maxCount={1}
            fileList={fileList}
            disabled={applicationID === 'tokenpocket_android'}
          >
            <p>
            <Button
              disabled={uploading == true}
              style={applicationID != 'tokenpocket_android' ? {height: '36px',
              marginRight:'5px'
            }:{
              height: '36px',
              marginRight:'5px',
              color: '#e5e5e5'
            }}
            >{t('Home.Select_File')}</Button>
            {uploading ? 'No file selected' : `${t('Home.Start_upload')}`}
            </p>
          </Upload>
          {applicationID == 'tokenpocket_android' &&
          <p style={{marginTop:'30px',color:'red'}}>The latest version of TP application does not support selecting files. Please try the upload function on Google Chrome.</p>
          }
          <div className="item-fileInfo"
            style={{display: (fileList.length !== 0 ? 'block' : 'none')}}
          >
            <p>{t('Home.Size_of_Upload_File')}:{formatBytes(fileSize, 2)}

            </p>
          </div>
        </Modal>


        <Spin tip="Loading..." spinning={load} className="MoveLoading">
        <div className="Mobile_terminal">
          {dataList != undefined && dataList.length != 0 ?
            <ul className="container">
              {dataList.map((item: any) => {
                return (
                  <li className="Mobile"
                    key={Math.random()}
                  >
                    <div className="Mobile_file">
                      <p >
                        {/* {t('Home.Document')} */}
                        <Tooltip
                          placement="topLeft"
                          title={decodeURIComponent(item.Name)}
                        >
                          {shortenFileName(decodeURIComponent(item.Name))}
                        </Tooltip>
                      </p>
                      <p onClick={()=>toggleVisible(item)}>
                        <Dropdown menu={{ items }}  placement="bottomRight" trigger={['click']}>
                          <a onClick={(e) => e.preventDefault()}>
                            ...
                          </a>
                        </Dropdown>
                        {/* <Button
                          type="primary"
                          size="large"
                          onClick={() => download(item)}
                        >
                          {t('Home.Download')}
                        </Button> */}
                      </p>
                    </div>
                    <div className="Mobile_file">
                      {/* <p>{t('Home.Size_of_File')}</p> */}
                      <p>{utc2beijing(item.ModTime)}&nbsp;&nbsp;&nbsp;{formatBytes(item.Size, 2)}</p>
                      {/* <p>{formatBytes(item.Size, 2)}</p> */}
                    </div>
                    {/* <div className="Mobile_file">
                      <p>{t('Home.File_Upload')}</p>
                      <p>{utc2beijing(item.ModTime)}</p>
                    </div> */}
                    <div className="Mobile_file">
                      {/* <p>MID</p> */}
                      <p><Tooltip
                        placement="topLeft"
                        title={(item.Mid)}
                      >{shortenFileName((item.Mid) ? (item.Mid).replace(/"/g, '') : '')}
                      </Tooltip>
                      </p>
                    </div>
                  </li>
                );
              },

              )}
            </ul>
            :
            <div className="Mobile_termina">
              {isUpload ?
                <div className="container" >
                  <span className="iconfont icon-weikong"/>
                  <p>{t('Home.No_data')}</p>
                </div>
                :
                <div className="container" >
                    <div className="container" >
                      <span className="iconfont icon-weikong"/>
                      <p>{t('Home.No_data')}</p>
                    </div>
                </div>
              }
            </div>
          }
          <div>
          </div>
        </div>
        </Spin>
        </>
  }
  {( status != 'connected' || !memoToken) &&
    <UploadPage/>
  }
    </div>
    </>
  );
};

export default HomePage;
