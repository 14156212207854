import React, { useState, useRef } from 'react';
import { Button, Form, Input, message, Avatar ,notification} from 'antd';
// import type { UploadProps } from 'antd/es/upload/interface';
import './index.scss';
import { useEffect } from 'react'; // useState
import { useApi, useToken } from '@/hooks/useAppState';
import { useNavigate } from 'react-router-dom';
//界面的图标
import {
  LeftOutlined
} from '@ant-design/icons';
import useMyWallet from '@/hooks/useMyWallet';
import { useProfile } from '@/hooks/useContract';
import { useTranslation } from 'react-i18next';
import type { FormInstance } from 'antd/es/form';
import sculpture from '@/assets/images/sculpture.png';
// import { constants } from 'buffer';

function Personal() {
	const formRef = React.useRef<FormInstance>(null);
	const { account, status, chainId } = useMyWallet();
	const { memoToken, profile, setProfile, setIsUpdateProfile, IsUpdateProfile ,applicationID} = useToken(); //profile
	const { build, update } = useProfile(); //profile update,
	// const [userProfile, setUserProfile] = useState({});
	// const [loading, setLoading] = useState(false);
	//返回上一级菜单
	const history = useNavigate();
	const [api, contextHolder] = notification.useNotification();
	const [touDi, setTouDi] = useState('');

	const [preview, setPreview] = useState(null);
	const [isload, setIsload] = useState(false);
	const fileInput = useRef(null);
	const API = useApi();
	const { t } = useTranslation();

	console.log('profile',profile);
	const handleInputChange = (event) => {
		const files = event.target.files;
		if (files.length > 0) {
			handleUpload(files[0]);
		}
	};
	function handleUpload(file) {
		setIsload(true);
		const formData = new FormData();
    const randomNum = Math.floor(Math.random() * 1000000); // 生成一个介于0和1000000之间的随机整数
    const fileName = `${randomNum}_${file.name}`; // 附加随机数到文件名中
    formData.append('file', file, fileName); // 将附加了随机数的文件名添加到formData对象中
		formData.append('public', 'true');
		setPreview(URL.createObjectURL(file));
		API({
			method: 'POST',
			url: 'https://api.mefs.io:10000/mefs/',
			headers: {
				'Content-Type': 'multipart/form-data'
			},
			data: formData
		}).then(function (res) {
			setIsload(false);
			if (res.status == 200) {
				setTouDi(res.data.Mid);
				console.log('res值',res.data.Mid);
				message.success(`${t('Home.upload_modify')}`);
			} else {
				message.error(`${t('Home.upload_download')}`);
			}
		});
	}
	const UpDate = () => {
		setIsUpdateProfile(!IsUpdateProfile);
	};
	useEffect(() => {
		if (memoToken && status === 'connected') {
			if (profile && profile.name)
				formRef.current?.setFieldsValue(profile);
		} else {
			formRef.current?.setFieldsValue({});
		}
	}, [profile, memoToken, status, account,profile.register]);

	const ErrFun = (error) => {
		if(error != ''){
			api['error']({
        message: 'Error',
        description: t('NFTCreate.Submission')+JSON.stringify(error).slice(-300)
      });
		}else{
			api['success']({
        message: 'Success',
        description:
          t('NFTCreate.Successfully')
      });
			profile.register = true;
		}
	};
	
	const buildHandle = () => {
		build(account, chainId, setIsload, ErrFun);
	};
	const updateHandle = async(values: any) => {
		values.avatar = touDi || profile.avatar;
		profile.avatar = values.avatar;
		setProfile(profile);
		await update(account, chainId, { address: account, entries: values }, setIsload, UpDate, ErrFun);
	};

	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 16 }
	};

	/* eslint-disable no-template-curly-in-string */
	const validateMessages = {
		required: '${label} is required!',
		types: {
			email: '${label} is not a valid email!',
			number: '${label} is not a valid number!'
		},
		number: {
			range: '${label} must be between ${min} and ${max}'
		}
	};
	//返回上一级界面
	function goBack() {
		history(-1);
	}

	return (
		<div className="Personal">
			{contextHolder}
			<div className="container">
				<div className="Home">
					<p className="containerBos" onClick={goBack}><LeftOutlined />{t('Personal.Profile')}</p>
					<div className="Home_Personal">

						{/* <Form.Item label="Upload" valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload {...props}  listType="picture-card" maxCount={1} >
            <div>
              <PlusOutlined />
              <div >Upload</div>
            </div>
          </Upload>
        </Form.Item> */}
						{!profile?.register &&
							<Button onClick={buildHandle} loading={isload} type="primary" htmlType="button" className="AddButton">
								{t('Personal.Register_Profile')}
							</Button>
						}
						{profile?.register &&
							<Form ref={formRef} {...layout} name="nest-messages" onFinish={updateHandle} style={{ maxWidth: 600 }} validateMessages={validateMessages}>
								<div className="file-input-container" >
									<div className="file-input-file">
										<i className="iconfont icon-bianji" />
										<input type="file" onChange={handleInputChange} ref={fileInput} accept="image/*" disabled={applicationID === 'tokenpocket_android'} />
									</div>
									<label className="file-input-label" >
									</label>
									<Avatar onClick={() => fileInput.current.click()} size={80} src={preview || profile['avatardata'] || sculpture}
									/>
								</div>
								{
									applicationID === 'tokenpocket_android' &&
									<p style={{marginTop:'-10px',color:'red'}}>The latest version of TP application does not support selecting files. Please try the upload function on Google Chrome.</p>
								}
								<Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please input your Username!' },
								{
									pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
									message: 'Username can only contain letters and Chinese characters'
								}]}>
									<Input />
								</Form.Item>
								<Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
									<Input />
								</Form.Item>
								<Form.Item name="twitter" label="Twitter" rules={[{ required: false }]}>
									<Input />
								</Form.Item>
								<Form.Item name="phone" label="Phone" rules={[{ required: false }]}>
									<Input />
								</Form.Item>
								<Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
									<Button type="primary" loading={isload} htmlType="submit" className="SaveButton">
										{t('Personal.Update_Profile')}
									</Button>
								</Form.Item>
							</Form>
						}

					</div>
				</div>
			</div>
		</div>
	);
}

export default Personal;
