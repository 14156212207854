import React, { createContext, useCallback, useState,useEffect } from 'react';
import { DefaultChainId } from '@/constants/chains';
import { CHAIN_INFO } from '@/constants/chainInfo';
export const Context = createContext({switchChain: null, selectChainId: null, setSelectChainId: null,selectChainTitle: null, setSelectChainTitle: null,haveswitch:true,setHaveswitch: null,visibility:true,setVisibility:null});

const setLocalValue = (key, value)=> {
  if (window && window.localStorage) {
    try {
     window.localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
       console.log(err);

   }
  }
};
const getLocalValue =(key) =>{
  if (window && window.localStorage) {
    if (!window.localStorage.getItem(key)) {
      return '';
    }
    try {
      return JSON.parse(window.localStorage.getItem(key));
    } catch (err) {
       return '';
    }
  } else {
    return '';
  }
};
interface IProps {
  children: any
}

const NetworkProvider: React.FC<IProps> = ({children}) => {
  const [haveswitch,setHaveswitch] = useState(true);
  const [visibility,setVisibility] = useState(true);
  const [selectChainId,setSelectChainId] = useState(getLocalValue('chainid') || DefaultChainId);
  const [selectChainTitle,setSelectChainTitle] = useState(CHAIN_INFO[selectChainId] && CHAIN_INFO[selectChainId].label || 'Memo Megrez');
  useEffect(() => {
    setSelectChainTitle(CHAIN_INFO[selectChainId].label  || 'Memo Megrez');
    setLocalValue('chainid',selectChainId);
    console.log(selectChainId);
  }, [selectChainId]);

  const switchChain = useCallback(
    async ({ chainId, chainName, nativeCurrency, rpcUrls, explorerUrl}) => {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId }]
        });
      } catch (e: any) {
        if (e.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [{
                chainId,
                chainName,
                nativeCurrency,
                rpcUrls,
                blockExplorerUrls: [explorerUrl]
              }]
            });
          } catch (addError) {
            // console.log(addError);
          }
        }
      }
    },
    []
  );



  return (
    <Context.Provider
      value={{
        switchChain: switchChain,
        selectChainId,
        setSelectChainId,
        selectChainTitle,
        setSelectChainTitle,
        haveswitch,
        setHaveswitch,
        visibility,
        setVisibility

      }}
    >
      {children}
    </Context.Provider>
  );
};

export default NetworkProvider;