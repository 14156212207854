export const SupportedChainId = {
  ETH: 1,
  MEMO:985,
  MATIC:137,
  METER: 82,
  IOTX:4689,
  METIS:1088,
  BSC: 56,
  BSC_TEST: 97,
  ARB:42161,
  MEMO_TEST:1985,
  MATIC_TEST:1422,
  zkSync:324
};

export const DefaultChainId = SupportedChainId.MEMO;

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.ETH]: 'Ethereum Mainnet',
  [SupportedChainId.MEMO]: 'Memo Megrez',
  [SupportedChainId.MATIC]: 'Polygon Mainnet',
  [SupportedChainId.METER]: 'Meter Mainnet',
  [SupportedChainId.METIS]: 'Metis Andromeda Mainnet',
  [SupportedChainId.IOTX]: 'IoTeX Network Mainnet',
  [SupportedChainId.MEMO_TEST]: 'Memo TestNet',
  [SupportedChainId.MATIC_TEST]: 'MATIC TestNet',
  [SupportedChainId.ARB]: 'Arbitrum'
  // [SupportedChainId.zkSync]: 'zkSync'
};